
import { useRef, useEffect } from "react";

export const useClickOutside = (callback) => {
  const elementRef = useRef(null);

  const handleDocumentClick = (event) => {
    if (!elementRef.current.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  });

  return [elementRef];
};

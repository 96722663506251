import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

import { lowlandsService } from "../services/lowlands-service";

import { BottomNavigationPanel } from "../components/BottomNavigationPanel";
import { PipVideoContainer } from "../components/PipVideoContainer";
import { BackgroundVideo } from "../components/BackgroundVideo";

const Wrapper = styled.div`
  position: relative;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    overflow: hidden;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const Layout = ({ children, ...rest }) => {
  const canonical = `https://${
    window.location.host + window.location.pathname
  }`;

  return (
    <Wrapper>
      <Helmet>
        <title>Lowlands Free:United</title>
        <meta
          name="description"
          content="LL20 gaat door. Organiseer je eigen LLowparty thuis, op de camping of in je stamkroeg en wie weet komen wij wel langs."
        />
        <link rel="canonical" href={canonical} />
      </Helmet>

      <BackgroundVideo />
      <ContentWrapper {...rest}>{children}</ContentWrapper>
      {!lowlandsService.isAfterState() && <BottomNavigationPanel />}
      {lowlandsService.isLiveState() && <PipVideoContainer />}
    </Wrapper>
  );
};

export default Layout;

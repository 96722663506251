import React from "react";
import styled from "styled-components";
import { ReactComponent as LogoLowlands } from "../static/icons/lowlands-logo.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledLogoLowlands = styled(LogoLowlands)`
  width: ${({ big }) => (big ? "8rem" : "5rem")};
  height: ${({ big }) => (big ? "8rem" : "5rem")};
  animation: rotate 1.25s infinite linear;

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const PageLoader = ({ big = false }) => {
  return (
    <Wrapper>
      <StyledLogoLowlands big={big ? 'true' : null} />
    </Wrapper>
  );
};

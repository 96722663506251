import React from "react";
import TitleAndText from "../TitleAndText";
import BlockWrapper from "./BlockWrapper";

const TitleAndTextBlock = ({ block }) => {
  return (
    <BlockWrapper>
      <TitleAndText title={block.mainTitle} text={block.text} />
    </BlockWrapper>
  );
};

export default TitleAndTextBlock;

export const theme = {
  // Colours
  primaryColor: "#000",
  white: "#fff",
  gray: "#d3d3d3",
  black: "#000",
  purple: "#B839EC",
  green: "#44FC87",
  turquoise: "#50F1DD",
  yellow: "#F8D903",
  red: "#FF0000",
  blue: "#1F4CF9",
  lightBlue: "#5794F7",

  // Fonts
  fontSize: "1.8rem",
  fontFamily:
    '"LL20HvH", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontFamilyPartners:
    '"Mikro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontFamilyRegular:
    '"Hind", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',

  // Sizing
  maxContainerWidth: "1400px",
  maxContentWidth: "100%",
  maxPanelWidth: "96rem",

  // transition
  transition: "cubic-bezier(0.165, 0.84, 0.44, 1)",

  // Breakpoints
  respondTo: {
    desktopXXL: "min-width: 1600px",
    desktopXL: "min-width: 1440px",
    desktopL: "min-width: 1280px",
    desktop: "min-width: 1024px",
    tabletAndMobile: "max-width: 1023.999px",
    tablet: "min-width: 481px",
    mobile: "max-width: 480px",
    mobileS: "max-width: 320px",
    widescreen: "min-aspect-ratio: 16/9",
  },
};

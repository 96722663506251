import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import { ROUTES } from "../routes";
import { Title } from "./interface/Title.jsx";
import { AspectRatioBox } from "./interface/AspectRatioBox.jsx";
import { Button } from "./interface/Button.jsx";
import Map from "../static/images/map.jpg";

const RegisterPanelStyled = styled.div`
  background: ${({ theme, background }) => theme[background] || theme.yellow};
  z-index: 1;
  width: 100%;
  position: sticky;
  top: 26rem;
  align-self: flex-start;
  text-align: center;
  ${({ isStatic }) => isStatic && `margin: 0 auto 5rem;`}

  @media (${({ theme }) => theme.respondTo.tablet}) {
    min-width: 30rem;
    width: 30rem;
  }
  @media (${({ theme }) => theme.respondTo.desktop}) {
    top: 15vh;
    min-width: 40rem;
    width: 40rem;
  }

  ${Title} {
    position: relative;
    font-size: 3rem;
    margin: 2rem 0 0;
  }
  ${Button} {
    display: block;
  }
`;

const Content = styled.div`
  padding: 1.6rem;
  p {
    font-size: 1.4rem;
    line-height: 1.4;
    color: ${({ theme }) => theme.purple};
    text-transform: none;
    margin-bottom: 1.6rem;
  }
`;

const RegisterPanel = ({ isStatic }) => {
  const location = useLocation();

  return (
    <RegisterPanelStyled isStatic={isStatic}>
      <AspectRatioBox ratioHeight="30vh" background="yellow">
        <img src={Map} alt="placeholder map" />
      </AspectRatioBox>
      <Title as="h2" noShadow color="purple">
        Aanhaken is meemaken
      </Title>
      <Content>
        <p>
          Ben jij van plan om thuis Lowlands te vieren én wil je kans maken op
          een onvergetelijk optreden bij jou in Achtertuinië of op de Costa
          Balkonia? Geef je dan nu op als Lowlands locatie! Wie weet staat 3FM
          in het weekend van 21, 22 en 23 augustus ineens voor je schutting met
          een uniek Lowlands-feestje, inclusief livemuziek. Maar wat je ook gaat
          doen, zorg in ieder geval dat het 100% coronaproof is en houd je aan
          de RIVM-richtlijnen.
        </p>
        {![ROUTES.SIGN_UP_FORM.URL, ROUTES.SIGN_UP_SUCCES.URL].includes(
          location.pathname
        ) && (
          <Button as={Link} to={ROUTES.SIGN_UP_FORM.URL} background="purple">
            jouw LLowparty aanmelden
          </Button>
        )}
      </Content>
    </RegisterPanelStyled>
  );
};

export default RegisterPanel;

import { createGlobalStyle } from "styled-components";

import LLFont from "../static/fonts/LL20HvH-HammerEight.woff";
import LLFont2 from "../static/fonts/LL20HvH-HammerEight.woff2";

import Mikro from "../static/fonts/Mikro-Black.woff";
import Mikro2 from "../static/fonts/Mikro-Black.woff2";

import HindRegular from "../static/fonts/Hind-Regular.woff";
import HindRegular2 from "../static/fonts/Hind-Regular.woff2";

import HindBold from "../static/fonts/Hind-Bold.woff";
import HindBold2 from "../static/fonts/Hind-Bold.woff2";

// set basic styling and 'normalize' things
const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "LL20HvH";
    src: url(${LLFont}) format('woff'),
      url(${LLFont2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Mikro";
    src: url(${Mikro}) format('woff'),
      url(${Mikro2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Hind";
    src: url(${HindRegular}) format('woff'),
      url(${HindRegular2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Hind";
    src: url(${HindBold}) format('woff'),
      url(${HindBold2}) format('woff2');
    font-weight: bold;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  main {
    padding: 0;
    overflow: hidden;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    position: relative;
    padding: 0;
    margin: 0;
    height: auto;
    width: 100%;
    font-size: 1.8rem;
    font-family: ${({ theme }) => theme.fontFamilyRegular};
    background-color: white;
    color: ${({ theme }) => theme.black};
    overscroll-behavior-y: none;
    overflow: ${({ disablePageOverflow }) =>
      disablePageOverflow ? "hidden" : "visible"};
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: normal;
		line-height: 1;

    + p,
    + ul {
      margin-top: 1.5rem;
    }
  }

  h1 {
    font-size: 8rem;
  }

  h2 {
    font-size: 3.5rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 2rem;
  }

  p {
    margin: 0;
    letter-spacing: -0.005em;
    font-size: 1.6rem;
    line-height: 1.4;

    + p {
      margin-top: 1.5rem;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

	img {
		max-width: 100%;
		display: block;
	}

  ul, ol {
    padding: 0;
    margin: 0;
  }

`;

export default GlobalStyle;

import styled from "styled-components";

export const CloseButton = styled.button`
  position: relative;
  padding: 0;
  display: block;
  cursor: pointer;
  font-family: inherit;
  appearance: none;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  width: 100%;
  height: 100%;
  color: transparent;
  user-select: none;
  width: 4rem;
  height: 4rem;
  &::before,
  &::after {
    width: 100%;
    height: 0.3rem;
    background: ${({ theme }) => theme.white};
    background: ${({ theme, background }) => theme[background]};
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    will-change: transform;
    transition: transform 0.4s ${({ theme }) => theme.transition};
    transform-origin: center;
    width: 2.25rem;
    @media (${({ theme }) => theme.respondTo.desktop}) {
      width: 3rem;
    }
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    background-color: ${({ theme }) => theme.purple};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    width: 5.6rem;
    height: 5.6rem;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    &::before {
      transform: translate(-50%, -50%) rotate(35deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-35deg);
    }
  }
`;

export const CloseButtonTopLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    top: 1rem;
    left: 1rem;
  }
`;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { ROUTES } from "../routes";
import Layout from "../layouts/Challenge.jsx";
import { Title as TitleStyled } from "../components/interface/Title.jsx";
import { Heading } from "../components/interface/Heading.jsx";
import { Lead as LeadStyled } from "../components/interface/Lead.jsx";
import { Back } from "../components/interface/Back.jsx";
import { Button } from "../components/interface/Button.jsx";
import { Panel } from "../components/interface/Panel.jsx";
import { ScrollToTop } from "../components/ScrollToTop.jsx";

import ToolkitImg from "../static/images/toolkit.png";

const Wrapper = styled.div`
  @media (${({ theme }) => theme.respondTo.tablet}) {
    /* 100vh - padding top of layout wrapper - height of the register panel */
    padding-bottom: calc((100vh - 26rem) - (30vh + 41.9rem));
  }
  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding-bottom: calc(85vh - (30vh + 30.1rem));
  }
`;

const Title = styled(TitleStyled)`
  margin-bottom: 1.5rem;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    max-width: calc(100% - 20rem);
  }
  margin-left: auto;
  margin-right: auto;
`;

const Lead = styled(LeadStyled)`
  margin-top: 0;
  max-width: 72rem;
  padding-left: 10rem;
  padding-right: 10rem;
`;

const ToolkitBox = styled.div`
  position: relative;
  margin-bottom: 10rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin-bottom: 5rem;
  }

  ${Button} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    margin: 0;
    white-space: nowrap;
  }

  img {
    width: 140%;
    margin: 0px -15%;
    max-width: none;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin: 0 auto;
      width: 100%;
      max-width: 52rem;
    }
  }
`;

const Success = () => {
  return (
    <Layout>
      <Helmet>
        <title>Successvol aangemeld | Lowlands Free:United</title>
        <meta
          name="description"
          content="Laat ons vooral weten hoe jouw LLowparty er straks uit gaat zien.
            Anderhalvemeter-Alpha’s, handgelcocktailbars,
            mondkapjesmeezingfestijnen, een silent disco met DJ Van Dissel & MC
            Irma; kom maar op!"
        />
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <ScrollToTop />
      <Wrapper>
        <Panel background="blue" small>
          <Back as={Link} to={ROUTES.HOME.URL}>
            Sluiten
          </Back>
          <Title color="yellow" noShadow>
            Gelukt
          </Title>
          <Lead>
            Laat ons vooral weten hoe jouw LLowparty er straks uit gaat zien.
            Anderhalvemeter-Alpha’s, handgelcocktailbars,
            mondkapjesmeezingfestijnen, een silent disco met DJ Van Dissel & MC
            Irma; kom maar op!
          </Lead>

          <ToolkitBox>
            <img src={ToolkitImg} alt="Lowlands Toolkit" />
            <Button as={Link} to={ROUTES.TOOLKIT.URL} background="green">
              Donwload de LL toolkit
            </Button>
          </ToolkitBox>
          <p>Superspread the LLove!</p>
          <Heading color="yellow">#LL20 #3FM #LLthuis</Heading>
        </Panel>
      </Wrapper>
    </Layout>
  );
};

export default Success;

import React from "react";
import TitleAndText from "./blocks/TitleAndText";

const renderBlock = (block, i) => {
  switch (block.handle) {
    case "text":
      return <TitleAndText key={i} block={block.data} />;
    default:
      return;
  }
};

export const Blocks = (props) => {
  return <>{props.blocks.map((block, i) => renderBlock(block, i))}</>;
};

import React, { useEffect } from "react";
import styled from "styled-components";

import { useStore, STORE_ACTIONS } from "../hooks/store";
import { Panel } from "../components/interface/Panel.jsx";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ paddingTop }) =>
    paddingTop ? `${paddingTop} 0 0` : `27rem 0 0`};
  min-height: calc(100vh);

  @media (${({ theme }) => theme.respondTo.tablet}) {
    padding: 15vh 2.7rem 0;
  }
`;

const PanelLayout = ({
  children,
  backgroundVideoName,
  background,
  paddingTop,
  ...rest
}) => {
  const { dispatch } = useStore();

  useEffect(() => {
    dispatch({
      type: STORE_ACTIONS.SET_VIDEO_BACKGROUND_COLOR,
      color: background || "purple",
    });
    if (!backgroundVideoName) return;
    dispatch({
      type: STORE_ACTIONS.SET_VIDEO_BACKGROUND,
      name: backgroundVideoName,
    });
  }, [dispatch, backgroundVideoName, background]);

  return (
    <Wrapper paddingTop={paddingTop}>
      <Panel {...rest} background={background}>
        {children}
      </Panel>
    </Wrapper>
  );
};

export default PanelLayout;

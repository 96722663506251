import styled from "styled-components";

export const Lead = styled.p`
  font-size: 1.9rem;
  line-height: 150%;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
  max-width: ${({ theme }) => theme.maxContentWidth};
  margin-left: auto;
  margin-right: auto;
  * {
    font-size: inherit;
    line-height: inherit;
  }
`;

import React, { useEffect, useState, useCallback, useRef } from "react";
import styled, { css } from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useMediaQuery } from "beautiful-react-hooks";
import { Helmet } from "react-helmet-async";

import { theme } from "../styling/theme";
import { useStore, STORE_ACTIONS } from "../hooks/store";
import { ROUTES } from "../routes";
import { apiLowlands } from "../apis/api-lowlands";
import { hexToRgba } from "../utils/helpers";
import { BACKGROUND_VIDEOS } from "../constants/media";

import Layout from "../layouts/Panel.jsx";
import { Title as TitleStyled } from "../components/interface/Title.jsx";
import { AspectRatioBox } from "../components/interface/AspectRatioBox.jsx";
import { Switch } from "../components/interface/Switch.jsx";
import { Button } from "../components/interface/Button.jsx";
import { CloseButton } from "../components/interface/CloseButton.jsx";
import { Slider, Slide } from "../components/layout/Slider.jsx";
import { PageLoader } from "../components/PageLoader.jsx";

import { ReactComponent as ResizeSVG } from "../static/icons/resize.svg";

const SLIDER_BREAKPOINTS = {};

const SLIDES_SPACE_BETWEEN_PX = 0;

const Title = styled(TitleStyled).attrs({ center: true })`
  margin-bottom: 1.6rem;
  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin-bottom: 2.4rem;
  }
`;

const SwitchHolder = styled.div``;

const ArtistListWrapper = styled(motion.div)`
  position: relative;

  flex: 1;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: ${({ theme }) => css`linear-gradient(
      to bottom,
      ${hexToRgba(theme.yellow, 1)} 0%,
      ${hexToRgba(theme.yellow, 0.8)} 40%,
      ${hexToRgba(theme.yellow, 0)} 100%
    )`};
    height: 4rem;
    @media (${({ theme }) => theme.respondTo.tablet}) {
      height: 6rem;
    }
    content: "";
    z-index: 1;
  }
`;

const ArtistList = styled.ul`
  padding-top: 4rem;
  list-style: none;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (${({ theme }) => theme.respondTo.tablet}) {
    padding-top: 6rem;
    overflow: hidden;
    overflow-y: scroll;
  }
`;

const ArtistListItem = styled.li`
  font-family: ${({ theme }) => theme.fontFamily};
  margin-bottom: 1rem;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin-bottom: 1.25rem;
  }
`;

const HoverLinkText = styled.span`
  position: relative;
  z-index: 1;
`;

const HoverLink = styled(Link)`
  position: relative;
  font-size: 2.4rem;
  display: table;
  margin-left: auto;
  margin-right: auto;
  line-height: 0.9;
  margin-bottom: 0;
  text-align: center;
  @media (${({ theme }) => theme.respondTo.tablet}) {
    font-size: 4.8rem;
  }

  &:hover {
    @media (${({ theme }) => theme.respondTo.desktop}) {
      color: ${({ theme }) => theme.purple};
    }
  }
`;

const HoverLinkImage = styled(motion.div)`
  display: none;
  pointer-events: none;
  width: 30rem;
  overflow: hidden;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.12);

  @media (${({ theme }) => theme.respondTo.desktop}) {
    box-shadow: 0 0.6rem 0.9rem rgba(0, 0, 0, 0.12);
  }

  img {
    background: ${({ theme }) => theme.purple};
    width: 100%;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    display: block;
  }
`;

const BlockSchemeWrapper = styled.div`
  margin-top: 3.2rem;
  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin-top: 4rem;
  }
`;

const BlockScheme = styled(motion.div)`
  position: relative;

  z-index: 3;
  img {
    cursor: pointer;
  }
`;
const BlockSchemeFull = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  transform-origin: center;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  padding: 2rem;
  overflow: hidden;
  overflow-y: scroll;
  cursor: pointer;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (${({ theme }) => theme.respondTo.mobile}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: 10vh 10vw;
  }
`;
const BlockSchemeFullBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => hexToRgba(theme.purple, 0.8)};
  content: "";
  z-index: 2;
`;

const FullScreenButton = styled.button`
  appearance: none;
  border: none;
  outline: none;
  width: 2.4rem;
  height: 2.4rem;
  background: ${({ theme }) => theme.purple};
  position: absolute;
  top: 0;
  right: 0;

  pointer-events: none;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 3rem;
    height: 3rem;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ButtonHolder = styled.div`
  margin-top: 2.4rem;
`;
const DownloadButton = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const CloseButtonHolder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  padding: 0;
  background: ${({ theme }) => theme.purple};
  @media (${({ theme }) => theme.respondTo.desktop}) {
    background: none;
    padding: 1rem;
  }
`;

const DayToggleHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.6rem;
  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin-bottom: 2.4rem;
  }
`;

const DayToggle = styled(Button).attrs({ color: "red" })`
  background: transparent;
  padding: 0 0.8rem;
  font-size: 1.5rem;
  @media (${({ theme }) => theme.respondTo.tablet}) {
    font-size: 1.8rem;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.purple};
    `}
`;

const panelVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.4, easing: "easeIn" },
  },
};

const blockSchemeVariants = {
  initial: {
    scale: 0,
  },
  animate: {
    scale: 1,
    transition: { duration: 0.3, easing: "easeIn" },
  },
  exit: {
    scale: 0,
    transition: { duration: 0.3, easing: "easeIn", delay: 0.1 },
  },
};

const blockSchemeBackgroundVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.3, easing: "easeIn", delay: 0.2 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.1, easing: "easeIn", delay: 0 },
  },
};

const hoverImageVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.3, easing: "easeIn" },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.3, easing: "easeIn" },
  },
};

const Program = () => {
  const history = useHistory();
  const list = useRef();
  const isMobile = useMediaQuery(`(${theme.respondTo.mobile})`);
  const { dispatch } = useStore();
  const [isLineUp, setIsLineUp] = useState(
    history.location.state?.section === "lineUp"
  );
  const [lineUp, setLineUp] = useState([]);
  const [timetable, setTimetable] = useState([]);
  const [timetableFallback, setTimetableFallback] = useState(null);
  const [currentTimetableImage, setCurrentTimetableImage] = useState(null);
  const [currentActiveSlide, setCurrentActiveSlide] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [hoveredArtist, setHoveredArtist] = useState(null);
  const [y, setY] = useState(0);
  const [x, setX] = useState(0);

  const getProgram = async () => {
    const {
      lineup,
      timetable,
      timetableImages,
    } = await apiLowlands.getTimeTable();
    setTimetableFallback(timetable);
    setTimetable(timetableImages);
    setLineUp(lineup);
  };

  useEffect(() => {
    getProgram();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const activeItem = timetable.findIndex((t) => t.isToday);
    setCurrentActiveSlide(activeItem);
  }, [timetable]);

  const openFullScreen = (index) => {
    document.body.style.overflow = "hidden";
    setCurrentTimetableImage(timetable[index].image);
    dispatch({ type: STORE_ACTIONS.APPLICATION.HIDE_NAVIGATION });
    dispatch({ type: STORE_ACTIONS.APPLICATION.HIDE_PIP });
    dispatch({ type: STORE_ACTIONS.HIDE_SCROLLBAR });
    setFullScreen(true);
  };

  const closeFullScreen = () => {
    setFullScreen(false);
    setCurrentTimetableImage(null);
    document.body.style.overflow = "auto";
    dispatch({ type: STORE_ACTIONS.APPLICATION.SHOW_NAVIGATION });
    dispatch({ type: STORE_ACTIONS.APPLICATION.SHOW_PIP });
    dispatch({ type: STORE_ACTIONS.SHOW_SCROLLBAR });
  };

  const handleMouseOver = useCallback(
    (artist) => {
      setHoveredArtist(artist);
      const child = list?.current?.childNodes[artist.index];
      const parent = list?.current?.parentNode;
      if (!child) return;
      const offsetTop = `calc(${
        parent.offsetTop + child.offsetTop - list.current.scrollTop
      }px - 50%)`;

      const offsetLeft =
        artist.index % 2 === 0
          ? `calc(4.5rem - 50%)`
          : `calc(4.5rem + ${child.offsetWidth}px - 50%)`;

      setY(offsetTop);
      setX(offsetLeft);
    },
    [list]
  );

  return (
    <Layout
      background="yellow"
      color="red"
      fullFixed={!isMobile && isLineUp}
      backgroundVideoName={BACKGROUND_VIDEOS.RED.NAME}
    >
      <Helmet>
        <title>Programma | Lowlands Free:United</title>
        <meta
          name="description"
          content="Programma en lineup pagina voor het Lowlands Free:United weekend van
          21 + 22 + 23 augustus"
        />
      </Helmet>
      <Title color="red">Programma</Title>
      <SwitchHolder>
        <Switch
          routes={[{ text: "Blokkenschema" }, { text: "Line-up" }]}
          start={isLineUp ? 1 : 0}
          onSwitch={(index) => setIsLineUp(index === 1)}
        />
      </SwitchHolder>
      <AnimatePresence exitBeforeEnter>
        {isLineUp ? (
          <ArtistListWrapper
            variants={panelVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            key="lineup"
          >
            <ArtistList ref={list}>
              {lineUp.length > 0 &&
                lineUp.map((artist, i) => (
                  <ArtistListItem key={artist.slug}>
                    <HoverLink
                      to={`${ROUTES.PROGRAM_DETAIL.URL}${artist.slug}`}
                      onMouseOver={() =>
                        handleMouseOver({ ...artist, index: i })
                      }
                      onMouseOut={() => setHoveredArtist({})}
                    >
                      <HoverLinkText>{artist.title}</HoverLinkText>
                    </HoverLink>
                  </ArtistListItem>
                ))}
            </ArtistList>
          </ArtistListWrapper>
        ) : (
          <motion.div
            variants={panelVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            key="blockscheme"
          >
            <BlockSchemeWrapper>
              {timetable.length === 0 && !timetableFallback ? (
                <PageLoader />
              ) : timetable.length > 0 ? (
                <>
                  <DayToggleHolder>
                    {timetable.map((timetableItem, index) => (
                      <DayToggle
                        key={timetableItem.id}
                        isActive={currentActiveSlide === index}
                        onClick={() => setCurrentActiveSlide(index)}
                      >
                        {timetableItem.title}
                      </DayToggle>
                    ))}
                  </DayToggleHolder>
                  <Slider
                    breakpoints={SLIDER_BREAKPOINTS}
                    mode="snap"
                    spaceBetween={SLIDES_SPACE_BETWEEN_PX}
                    moveToSlide={currentActiveSlide}
                    setMoveToSlide={setCurrentActiveSlide}
                  >
                    {timetable.map((timetableItem, index) => (
                      <Slide key={timetableItem.id}>
                        <BlockScheme onClick={() => openFullScreen(index)}>
                          <img src={timetableItem.image} alt="blokkenschema" />
                          <FullScreenButton aria-hidden>
                            <ResizeSVG />
                          </FullScreenButton>
                          <DownloadButton>
                            <Button
                              size="small"
                              center
                              as="a"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://content.omroep.nl/3fm/lowlands/2020/static/lowlands-free-united-blokkenschema.pdf"
                              onClick={(e) => e.stopPropagation()}
                            >
                              Download Blokkenschema
                            </Button>
                          </DownloadButton>
                        </BlockScheme>
                      </Slide>
                    ))}
                  </Slider>
                </>
              ) : (
                <>
                  <BlockScheme>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://content.omroep.nl/3fm/lowlands/2020/static/lowlands-free-united-blokkenschema.pdf"
                    >
                      {timetableFallback && (
                        <AspectRatioBox ratioHeight="49.85%" backgroud="purple">
                          <img src={timetableFallback} alt="blokkenschema" />
                        </AspectRatioBox>
                      )}
                    </a>
                  </BlockScheme>
                  <ButtonHolder>
                    <Button
                      size="small"
                      center
                      as="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://content.omroep.nl/3fm/lowlands/2020/static/lowlands-free-united-blokkenschema.pdf"
                    >
                      Download Blokkenschema
                    </Button>
                  </ButtonHolder>
                </>
              )}
              <AnimatePresence>
                {fullScreen && currentTimetableImage && (
                  <>
                    <BlockSchemeFull
                      key="blockScheme"
                      onClick={closeFullScreen}
                      variants={blockSchemeVariants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    >
                      <CloseButtonHolder>
                        <CloseButton>Sluiten</CloseButton>
                      </CloseButtonHolder>

                      <img src={currentTimetableImage} alt="blokkenschema" />
                    </BlockSchemeFull>
                    <BlockSchemeFullBackground
                      variants={blockSchemeBackgroundVariants}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    />
                  </>
                )}
              </AnimatePresence>
            </BlockSchemeWrapper>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        {hoveredArtist && isLineUp && (
          <HoverLinkImage
            key={hoveredArtist.slug}
            style={{ x, y }}
            variants={hoverImageVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <img src={hoveredArtist.imgUrl} alt={hoveredArtist.title} />
          </HoverLinkImage>
        )}
      </AnimatePresence>
    </Layout>
  );
};

export default Program;

import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useMediaQuery } from "beautiful-react-hooks";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import { ROUTES } from "../routes";
import { theme } from "../styling/theme";
import { BACKGROUND_VIDEOS } from "../constants/media";
import { useStore, STORE_ACTIONS } from "../hooks/store";
import { apiLowlands } from "../apis/api-lowlands";
import { getYoutubeVideoUrl, getBackgroundVideoPath } from "../utils/helpers";
import { FullscreenVideoContainer } from "../components/layout/FullscreenVideoContainer";
import { LiveStreamControls } from "../components/layout/LiveStreamControls";
import { ZoomControls } from "../components/layout/ZoomControls";
import { LiveStreamVideo } from "../components/LiveStreamVideo";
import { Title } from "../components/interface/Title";
import { BackgroundVideo } from "../components/BackgroundVideo";
import {
  CloseButton,
  CloseButtonTopLeft,
} from "../components/interface/CloseButton";
import { PageLoader } from "../components/PageLoader";

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${CloseButton} {
    z-index: 5;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.black};
  @media (${({ theme }) => theme.respondTo.mobile}) {
    background-color: ${({ black }) => (black ? theme.black : "transparent")};
  }

  color: ${({ theme }) => theme.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Video = styled.video`
  display: block;
  height: 100%;
  object-fit: cover;
`;

const Error = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  ${Title} {
    max-width: 80%;
    margin: 0 auto;
  }
`;
const Stream = () => {
  const isMobile = useMediaQuery(`(${theme.respondTo.mobile})`);
  const location = useLocation();
  const history = useHistory();
  const { state, dispatch } = useStore();
  const { slug, id } = useParams();
  const [isOpen, setIsOpen] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState(null);
  const [isYoutube, setIsYoutube] = useState(false);
  const [isLiveStream, setIsLiveStream] = useState(false);
  const [zoomUrl, setZoomUrl] = useState(null);
  const [showVideoSnippet, setShowVideoSnippet] = useState(false);
  const [snippetVideoUrl, setSnippetVideoUrl] = useState(false);
  const [showZoomPanel, setShowZoomPanel] = useState(false);
  const [showZoomCta, setShowZoomCta] = useState(false);
  const [noVideoIdFound, setNoVideoIdFound] = useState(false);
  const [showTempLoader, setShowTempLoader] = useState(true);

  const handlePlayerStart = () => {
    setIsPlaying(true);
  };

  const close = () => {
    setIsOpen(false);
    history.push(location.state?.from ? location.state.from : ROUTES.HOME.URL);
    dispatch({ type: STORE_ACTIONS.APPLICATION.SHOW_NAVIGATION });
    dispatch({ type: STORE_ACTIONS.APPLICATION.SHOW_PIP });
    dispatch({
      type: STORE_ACTIONS.SHOW_SCROLLBAR,
    });
  };

  const getYoutubeVideo = useCallback(async () => {
    const feedData = await apiLowlands.getFeed();
    const videoData = feedData.find((item) => item.id === Number(id));
    const hasYoutubeId = !!videoData.youtubeId;
    if (!hasYoutubeId) return setNoVideoIdFound(true);

    setYoutubeUrl(getYoutubeVideoUrl({ videoId: videoData.youtubeId }));
    setIsYoutube(true);
    setIsLiveStream(false);
    dispatch({ type: STORE_ACTIONS.APPLICATION.HIDE_NAVIGATION });
    dispatch({ type: STORE_ACTIONS.APPLICATION.HIDE_PIP });
  }, [dispatch, id]);

  const getStage = useCallback(async () => {
    const stageData = await apiLowlands.getStage({ slug });
    const hasLiveStream = !!stageData.livestreamId;
    const hasZoomUrl = !!stageData.zoomUrl;

    if (hasLiveStream || hasZoomUrl) {
      if (hasLiveStream) {
        setYoutubeUrl(getYoutubeVideoUrl({ videoId: stageData.livestreamId }));
        setIsYoutube(true);
        setIsLiveStream(true);
      }

      if (hasZoomUrl) setZoomUrl(stageData.zoomUrl);

      if (hasLiveStream && hasZoomUrl) {
        setShowZoomCta(true);
      } else if (!hasLiveStream && hasZoomUrl) {
        setShowZoomPanel(true);
        setSnippetVideoUrl(stageData.snippetVideo);
        setShowVideoSnippet(true);
      }
      dispatch({ type: STORE_ACTIONS.APPLICATION.HIDE_NAVIGATION });
      dispatch({ type: STORE_ACTIONS.APPLICATION.HIDE_PIP });
    } else {
      setNoVideoIdFound(true);
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (isYoutube) {
      setTimeout(() => {
        setShowTempLoader(false);
      }, 5000);
    }
  }, [isYoutube]);

  useEffect(() => {
    if (slug) getStage();
    if (id) getYoutubeVideo();
    if (
      isMobile &&
      state.backgroundVideoPath ===
        getBackgroundVideoPath({
          filename: BACKGROUND_VIDEOS.RED.FILENAME,
        })
    ) {
      dispatch({
        type: STORE_ACTIONS.SET_VIDEO_BACKGROUND,
        name: BACKGROUND_VIDEOS.RED.NAME,
      });
    }
    dispatch({
      type: STORE_ACTIONS.HIDE_SCROLLBAR,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Check for scale & mask state sent from the explore feed
  const scale = location.state?.scale > 1 ? 1 : location.state?.scale;
  const mask = location.state?.mask;

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        scale: scale ? scale : 0,
        clipPath: mask && `inset(${mask}% 0 ${mask}% 0)`,
        transition: { duration: 0.5 },
      }}
    >
      {isMobile && isOpen && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <BackgroundVideo />
          </motion.div>
        </AnimatePresence>
      )}
      {isYoutube && (
        <>
          <LiveStreamVideo
            liveStreamUrl={youtubeUrl}
            isMuted={isMobile ? true : !isOpen}
            onPlayerStart={handlePlayerStart}
            isOpen={isOpen}
          />
          {isLiveStream && isOpen && (
            <LiveStreamControls isPlaying={isPlaying} />
          )}
          {showTempLoader && (
            <LoaderWrapper black>
              <PageLoader big={true} />
            </LoaderWrapper>
          )}
        </>
      )}

      {showVideoSnippet && snippetVideoUrl && (
        <FullscreenVideoContainer>
          <Video
            muted
            loop
            autoPlay
            playsInline
            onClick={(event) => event.preventDefault()}
            onContextMenu={(event) => event.preventDefault()}
            onPlay={handlePlayerStart}
            onPlaying={handlePlayerStart}
          >
            <source src={snippetVideoUrl} type="video/mp4" />
            Your browser does not support HTML video.
          </Video>
        </FullscreenVideoContainer>
      )}

      {isOpen && (
        <>
          <ZoomControls
            showZoomPanel={showZoomPanel}
            showZoomCta={showZoomCta}
            zoomUrl={zoomUrl}
            isPlaying={isPlaying}
          />
          <CloseButtonTopLeft onClick={close}>
            <CloseButton />
          </CloseButtonTopLeft>
        </>
      )}

      {!isPlaying && noVideoIdFound && (
        <Error>
          <div>
            <Title color="white" center>
              Deze stream is momenteel offline.
            </Title>
            <Title color="white" center small style={{ marginTop: "2.4rem" }}>
              Probeer het later nog eens.
            </Title>
          </div>
        </Error>
      )}
      {!isPlaying && !noVideoIdFound && (
        <LoaderWrapper>
          <PageLoader big={true} />
        </LoaderWrapper>
      )}
    </Wrapper>
  );
};

export default Stream;

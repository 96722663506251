import React from "react";
import styled from "styled-components";

import { ReactComponent as LogoNpo3fm } from "../static/icons/npo-3fm.svg";
import { ReactComponent as LogoNpo } from "../static/icons/npo.svg";
import { ReactComponent as Logo3voor12 } from "../static/icons/3voor12.svg";
import { ReactComponent as LLLogo } from "../static/icons/lowlands-logo.svg";
import bgImage from "../static/images/loading-overlay@1x.png";
import bgImage2x from "../static/images/loading-overlay@2x.png";

const Wrapper = styled.div`
  z-index: 5;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  background: url(${bgImage}) ${({ theme }) => theme.black};
  background-size: cover;
  text-align: center;
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background: url(${bgImage2x}) ${({ theme }) => theme.black};
  }
`;

const Title = styled.h1`
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fontFamilyPartners};
`;

const Logos = styled.div`
  display: flex;
  justify-content: center;

  > * {
    height: 5rem;
    width: auto;
    margin: 2rem 1.5rem 0;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      height: 7rem;
      margin: 5rem 3rem 0;
    }
  }
`;

const Partners = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  top: 7rem;
  right: 3.5rem;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    display: none;
  }

  > div {
    display: flex;
    align-items: center;
    margin: 0 0 0 4.4rem;

    > svg {
      height: 3.5rem;

      &:first-child {
        margin-right: 2.4rem;
      }
    }
  }
`;

const Loading = styled.div`
  position: absolute;
  bottom: 4rem;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontFamilyPartners};
  font-size: 1.4rem;

  span {
    &::after {
      display: inline-block;
      animation: ellipsis 1.25s infinite;
      content: ".";
      width: 1rem;
      text-align: left;
    }
    @keyframes ellipsis {
      0% {
        content: ".";
      }
      33% {
        content: "..";
      }
      66% {
        content: "...";
      }
    }
  }
`;

const Loader = () => {
  return (
    <Wrapper>
      <Partners>
        <Title>Lowlands free:united is een initiatief van</Title>
        <div>
          <LogoNpo />
        </div>
      </Partners>
      <div>
        <Title color="white">3FM viert Lowlands free:united</Title>
        <Logos>
          <LogoNpo3fm />
          <Logo3voor12 />

          <LLLogo />
        </Logos>
      </div>
      <Loading>
        <span>Laden</span>
      </Loading>
    </Wrapper>
  );
};

export default Loader;

import React from "react";
import styled from "styled-components";

const Vid = styled.video`
  display: block;
  object-fit: cover;
  cursor: pointer;
`;

const Video = ({ url, ...rest }) => {
  return (
    <Vid
      muted
      loop
      autoPlay
      playsInline
      preload="auto"
      {...rest}
      // poster={item.stage.placeholderImages.feedLive}
    >
      <source src={`${url}#t=0.1`} type="video/mp4" />
      <source src={url} type="video/mp4" />
      Your browser does not support HTML video.
    </Vid>
  );
};

export default Video;

import React, { useState, memo, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import { EASINGS } from "../../constants/easings";

import { LikeAnimation } from "../LikeAnimation.jsx";

import HearthButtonBackground from "../../static/images/hearth.png";

const HEARTH_BUTTON_PANEL_TOTAL_Y_PX = 150;

const MotionLivePanel = styled(motion.div)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: ${({ theme }) => theme.white};
  z-index: 2;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    top: 2rem;
    right: 2rem;
  }
`;

const LiveLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.8rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.red};
  padding: 0 0.8rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 2.8rem;
    height: 3.8rem;
    line-height: 3.8rem;
    padding: 0 1rem;
  }
`;

const MotionLikePanel = styled(motion.div)`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 2;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    bottom: 2rem;
    right: 2rem;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(.9);
  }
  20% {
    transform: scale(1)
  }
  30% {
    transform: scale(0.9)
  }
  40%, 100% {
    transform: scale(1)
  }

`;

const LikeButton = styled(motion.button)`
  appearance: none;
  border: none;
  background: none;
  border-radius: 99999rem;
  color: transparent;
  overflow: hidden;
  position: relative;
  width: 6rem;
  height: 6rem;
  outline: none;
  background: ${({ theme }) => theme.black};
  cursor: pointer;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  animation-delay: 3s;
  animation: ${({ isLiking }) =>
    isLiking
      ? `none`
      : css`
          ${pulse} 4s linear infinite
        `};
  transition: transform 0.3s ${({ theme }) => theme.transition};

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${({ isLiking }) => isLiking && `opacity: 0.8;`}
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 10rem;
    height: 10rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      transform: scale(0.9);
      animation: none;
    }
  }
`;

const LikesWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  pointer-events: none;
`;

export const LiveStreamControls = memo(({ isPlaying }) => {
  const [isLiking, setIsLiking] = useState(false);

  const handleLike = () => {
    setIsLiking(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIsLiking(true);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLiking(true);
    }, 15000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <AnimatePresence>
      <MotionLivePanel
        key="motionLivePanel"
        initial={{
          scale: 0,
        }}
        animate={{
          scale: isPlaying ? 1 : 0,
          transition: {
            delay: 1,
            duration: 0.5,
            ease: EASINGS.EASE_OUT_BACK,
          },
        }}
      >
        <LiveLabel>Live</LiveLabel>
      </MotionLivePanel>
      <MotionLikePanel
        key="motionLikePanel"
        initial={{
          y: HEARTH_BUTTON_PANEL_TOTAL_Y_PX,
        }}
        animate={{
          y: isPlaying ? 0 : HEARTH_BUTTON_PANEL_TOTAL_Y_PX,
          transition: {
            duration: 1,
            ease: EASINGS.EASE_OUT_EXPO,
          },
        }}
      >
        <LikeButton
          isLiking={isLiking}
          onClick={!isLiking ? handleLike : undefined}
        >
          <img src={HearthButtonBackground} alt="Hearth icon" aria-hidden />
          Like
        </LikeButton>
      </MotionLikePanel>
      <LikesWrapper>
        {isLiking && (
          <LikeAnimation
            animationWidth={window.innerWidth}
            animationHeight={window.innerHeight}
            onDone={() => setIsLiking(false)}
          />
        )}
      </LikesWrapper>
    </AnimatePresence>
  );
});

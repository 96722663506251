import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  max-width: ${({ theme }) => theme.maxContentWidth};
  margin-left: auto;
  margin-right: auto;
  &:not(:last-child) {
    margin: 0 auto 2.5rem;
  }
`;

const BlockWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default BlockWrapper;

export const ROUTES = {
  HOME: {
    URL: "/",
  },
  STREAM_DETAIL: {
    ROUTER_PATH: "/stream/:slug",
    URL: "/stream/",
  },
  VIDEO_DETAIL: {
    ROUTER_PATH: "/video/:id",
    URL: "/video/",
  },
  PROGRAM: {
    URL: "/programma",
  },
  PROGRAM_DETAIL: {
    ROUTER_PATH: "/programma/:slug",
    URL: "/programma/",
  },
  NOWLIVE: {
    URL: "/live",
    API_ENDPOINT: "now_live",
  },
  CHALLENGE: {
    URL: "/challenge",
  },
  PARTY_SAFE: {
    URL: "/veilig-feesten",
    API_ENDPOINT: "celebrateSafe",
  },
  ABOUT: {
    URL: "/over-free-united",
    API_ENDPOINT: "about",
  },
  SIGN_UP_FORM: {
    URL: "/aanmelden",
  },
  SIGN_UP_SUCCES: {
    URL: "/succes",
  },
  TOOLKIT: {
    URL: "/toolkit",
  },
};

import React, { useEffect, useRef, useState } from "react";
import ReactYoutubePlayer from "react-player/youtube";
import styled, { withTheme } from "styled-components";
import { useMediaQuery } from "beautiful-react-hooks";

import { FullscreenVideoContainer } from "./layout/FullscreenVideoContainer";
import { ReactComponent as MutedSVG } from "../static/icons/audio-muted.svg";
import { ReactComponent as UnmutedSVG } from "../static/icons/audio-unmuted.svg";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const ButtonContainer = styled.div`
  position: absolute;
  left: 4rem;
  top: 0;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    left: 5rem;
    top: 1rem;
  }
`;

const StyledReactYoutubePlayer = styled(ReactYoutubePlayer)`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
`;

// This element prevents any user gestures from triggering the Youtube UI.
const PlayerCover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const MuteToggleButton = styled.div`
  width: 4rem;
  height: 4rem;
  padding: 1rem;
`;

export const LiveStreamVideo = withTheme(
  ({
    theme,
    canStartLiveStream = true,
    liveStreamUrl,
    isMuted,
    isOpen,
    showMobileControls = true,
    onPlayerStart,
    onPlayerPause,
    onPlayerResume,
  }) => {
    const isDesktopOrBigger = useMediaQuery(`(${theme.respondTo.desktop})`);
    const [isStreamStarted, setIsStreamStarted] = useState(false);
    const [isPlayerMuted, setIsPlayerMuted] = useState(true);
    const playerRef = useRef(null);
    let restartPlayerInterval = null;

    const isPlayerPlaying = () =>
      playerRef.current.getInternalPlayer().getPlayerState() === 1;
    const isPlayerPaused = () =>
      playerRef.current.getInternalPlayer().getPlayerState() === 2;

    const handlePlayerStart = () => {
      onPlayerStart && onPlayerStart();
      setIsStreamStarted(true);
    };

    /*
  Mobile browsers will pause the video when the browser window loses focus. When this happens,
  we monitor the browser window's visibility and try to restart the player.
   */
    const handlePlayerPause = () => {
      onPlayerPause && onPlayerPause();

      restartPlayerInterval = setInterval(() => {
        if (isPlayerPaused() && !document.hidden) {
          playerRef.current.getInternalPlayer().playVideo();
        } else if (isPlayerPlaying()) {
          clearInterval(restartPlayerInterval);
          onPlayerResume && onPlayerResume();
        }
      }, 500);
    };

    const unMutePlayer = () => {
      playerRef.current.getInternalPlayer().unMute();
      setIsPlayerMuted(false);
    };

    const mutePlayer = () => {
      playerRef.current.getInternalPlayer().mute();
      setIsPlayerMuted(true);
    };

    useEffect(() => {
      if (!isOpen) {
        if (playerRef.current && playerRef.current.getInternalPlayer()) {
          mutePlayer();
        }
      }
    }, [isOpen]);

    useEffect(() => {
      /*
      If the Youtube player is not ready yet to mute or unmute, the isStreamStarted
      dependency will trigger this hook again and call the correct mute method.
      */
      if (playerRef.current && playerRef.current.getInternalPlayer()) {
        if (isMuted) {
          mutePlayer();
        } else if (isDesktopOrBigger) {
          /*
        Automatically unmuting on mobile causes the player to pause, which causes a lot of bugs
        and edge cases. To enable unmuting on mobile we show buttons for muting and unmuting.
        */
          unMutePlayer();
        }
      }
    }, [isMuted, isStreamStarted]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Wrapper>
        <FullscreenVideoContainer>
          {canStartLiveStream && liveStreamUrl && (
            <StyledReactYoutubePlayer
              ref={playerRef}
              url={liveStreamUrl}
              width={"100%"}
              height={"100%"}
              volume={1}
              muted={true}
              playing={true}
              playsinline={1}
              onStart={handlePlayerStart}
              onPause={handlePlayerPause}
            />
          )}
          <PlayerCover />
        </FullscreenVideoContainer>
        {!isDesktopOrBigger && showMobileControls && isOpen && (
          <ButtonContainer>
            {isPlayerMuted ? (
              <MuteToggleButton onClick={unMutePlayer}>
                <MutedSVG />
              </MuteToggleButton>
            ) : (
              <MuteToggleButton onClick={mutePlayer}>
                <UnmutedSVG />
              </MuteToggleButton>
            )}
          </ButtonContainer>
        )}
      </Wrapper>
    );
  }
);

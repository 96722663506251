import React, { useRef, useEffect, useState } from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { useHistory } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import { motion, useMotionValue, useTransform } from "framer-motion";

import { ROUTES } from "../routes";
import { BACKGROUND_VIDEOS } from "../constants/media";
import { useStore, STORE_ACTIONS } from "../hooks/store";
import { getScaleValue, getMaskValue } from "../utils/helpers";
import { Title } from "./interface/Title";
import Video from "../components/Video";
import Image from "../components/Image";
import Card from "../components/Card";
import { PageLoader } from "./PageLoader";
import {
  CloseButton,
  CloseButtonTopLeft,
} from "../components/interface/CloseButton.jsx";

const Wrapper = styled(motion.div)`
  position: relative;
  width: 100vw;
  height: 100vh;
  padding-bottom: ${({ portrait }) => (portrait ? "125%" : "56.25%")};
  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding-bottom: 56.25%;
  }

  overflow: hidden;
  pointer-events: ${({ isAnimating }) => (isAnimating ? "none" : "all")};
`;

const CardHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    @media (${({ theme }) => theme.respondTo.tablet}) {
      transform: scale(2);
    }

    @media (${({ theme }) => theme.respondTo.tabletAndMobile}) {
      position: relative;
      max-width: 32rem;
      overflow: hidden;
    }

    @media (${({ theme }) => theme.respondTo.mobileS}) {
      max-width: 29rem;
    }

    @media (${({ theme }) => theme.respondTo.desktop}) {
      transform: none;
    }
  }
`;

const ItemInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  z-index: 0;
`;

const TitleWrapper = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  width: 100%;
  pointer-events: none;
  padding: 0 1rem;
  text-align: center;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    padding: 0 5rem;
  }
`;

const StyledTitle = styled(Title)`
  color: ${({ theme }) => theme.turquoise};
  pointer-events: none;
  padding: 0.5rem 2rem;
  font-size: inherit;
`;

const ItemButton = styled.button`
  appearance: none;
  box-shadow: none;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.red};
  font-family: ${({ theme }) => theme.fontFamily};
  border: none;
  font-size: 1.5rem;
  padding: 0.15rem 0.75rem;
  margin: 1rem 0 0;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin: 3rem 0 0;
    padding: 1rem 2.25rem;
    font-size: 2rem;
  }
`;

const LiveReportItem = ({
  totalItems,
  setActiveIndex,
  activeIndex,
  item,
  deltaY,
  theme,
}) => {
  const ref = useRef(null);
  const isMobile = useMediaQuery(`(${theme.respondTo.mobile})`);
  const [isForward] = useState(deltaY > 0);
  const [scale, setScale] = useState(deltaY < 0 ? (isMobile ? 3.2 : 1.6) : 0);
  const [isReady, setIsReady] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(deltaY < 0 ? true : false);
  const [growToFullscreen, setGrowToFullscreen] = useState(false);
  const [isFullscreenSnippet, setIsFullscreenSnippet] = useState(false);
  const { state, dispatch } = useStore();
  const history = useHistory();

  // Constants
  const MASK_SCROLL_THRESHOLD_PX = isMobile ? 1500 : 700;

  // Masking the video
  const scrolled = useMotionValue(0);
  const scrolledAfterFullscreen = scrolled.get();
  const { current: mask } = useTransform(
    scrolled,
    [0, MASK_SCROLL_THRESHOLD_PX], // scroll range in pixels
    isForward ? [0, 50] : [50, 0] // interpolating from 0 to 50%
  );

  const duration = () => {
    if (mask === 50) {
      return 0;
    } else if (growToFullscreen) {
      return 0.5;
    } else if (isMobile) {
      return 0.25;
    } else {
      return 0.5;
    }
  };

  useEffect(() => {
    if (!ref || !isReady) return;

    const maskValue = getMaskValue(scrolledAfterFullscreen, isForward, deltaY);
    let scaleValue = getScaleValue(scale, deltaY);
    const { x, y } = ref.current.getBoundingClientRect();

    // Scrolling up
    if (deltaY > 0) {
      // set fullscreen state if both x and y boundries are hit
      y <= 0 && x <= 0 ? setIsFullscreen(true) : setIsFullscreen(false);

      if (isFullscreen) {
        scrolled.set(maskValue);

        // If the mask is fully done, load the new video
        if (
          (maskValue >= MASK_SCROLL_THRESHOLD_PX && isForward) ||
          maskValue <= 0
        ) {
          setActiveIndex(activeIndex >= totalItems - 1 ? 0 : activeIndex + 1);
        }
      } else {
        setScale(scaleValue);
      }
      // scrolling down
    } else if (deltaY < 0) {
      if (scaleValue === 0) {
        setActiveIndex(activeIndex === 0 ? totalItems - 1 : activeIndex - 1);
      }
      if (isFullscreen) {
        scrolled.set(maskValue);

        if (maskValue >= MASK_SCROLL_THRESHOLD_PX || maskValue <= 0)
          setIsFullscreen(false);
        if (maskValue >= MASK_SCROLL_THRESHOLD_PX && !isMobile) {
          dispatch({ type: STORE_ACTIONS.UPDATE_VIDEO_BACKGROUND_INDEX });
        }
      } else {
        setScale(scaleValue);
      }
    }
  }, [deltaY]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if (item.stage?.isLive === "upcoming") return;
    if (isMobile && state.seenFirstFeedItem) return;

    if (isFullscreen && deltaY > 0) {
      if (!state.seenFirstFeedItem) {
        dispatch({ type: STORE_ACTIONS.SEEN_FIRST_FEED_ITEM });
      }

      if (isMobile) {
        dispatch({
          type: STORE_ACTIONS.SET_VIDEO_BACKGROUND,
          name: BACKGROUND_VIDEOS.RED.NAME,
        });
      } else {
        dispatch({ type: STORE_ACTIONS.UPDATE_VIDEO_BACKGROUND_INDEX });
      }
    }
  }, [isFullscreen, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => setIsReady(true), 50);
  }, []);

  const getItem = (item) => {
    if (item.stage) {
      const { isLive, snippetVideo } = item.stage;
      if (isLive === "live") {
        return snippetVideo === null || snippetVideo.length === 0 ? (
          <Image
            url={item.stage.placeholderImages.feedLive}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <Video url={item.stage.snippetVideo} style={{ cursor: "pointer" }} />
        );
      } else if (isLive === "upcoming") {
        return (
          <CardHolder>
            <Card withDate big item={item} />
          </CardHolder>
        );
      }
    }
    if (item.type === "videoSnippet") {
      return <Video style={{ pointerEvents: "none" }} url={item.video} />;
    }
    if (item.type === "image") {
      return <Image url={item.image} />;
    }
  };

  const handleFullscreen = () => {
    setIsAnimating(false);

    if (!growToFullscreen) return;

    // After completion of the animation, change route
    if (item.type === "videoSnippet") {
      history.push({
        pathname: `${ROUTES.VIDEO_DETAIL.URL}${item.id}`,
        state: { scale: optScale, mask: optMask },
      });
    } else {
      history.push({
        pathname: `${ROUTES.STREAM_DETAIL.URL}${item.stage.slug}`,
        state: { scale: optScale, mask: optMask },
      });
    }

    // In the background, revert the video in the feed back to its last known scale
    setTimeout(() => setGrowToFullscreen(false), 1000);
  };

  const handleCloseFullscreenSnippet = () => {
    setIsFullscreenSnippet(false);
  };

  const handleClick = () => {
    setGrowToFullscreen(true);
  };

  if (!item)
    return (
      <Wrapper>
        <PageLoader />
      </Wrapper>
    );

  const hasClick =
    (item.stage && item.stage.isLive === "live") ||
    (item.type === "videoSnippet" && item.youtubeId);

  // Optimize scale & mask values by reducing the amount of decimals to 2
  const optMask = Math.round(mask * 100) / 100;
  const optScale = Math.round(scale * 100) / 100;
  return (
    <>
      <Wrapper
        portrait={item.stage?.isLive === "upcoming"}
        key={activeIndex}
        initial={{ opacity: 0, scale, transition: { duration: 0 } }}
        isAnimating={isAnimating}
        animate={{
          opacity: 1,
          scale: growToFullscreen ? 1 : optScale,
          height: growToFullscreen ? "100vh" : 0,
          clipPath: growToFullscreen
            ? `inset(0% 0% 0% 0%)`
            : activeIndex % 2 === 0
            ? `inset(${optMask}% 0 ${optMask}% 0)`
            : `inset( 0 ${optMask}% 0 ${optMask}%)`,
          transition: {
            duration: duration(),
            ease: [0.165, 0.84, 0.44, 1],
          },
        }}
        onAnimationStart={() => {
          setIsAnimating(true);
        }}
        onAnimationComplete={handleFullscreen}
      >
        {(item.title?.length > 0 || item.stage?.isLive === "live") && (
          <TitleWrapper
            initial={{ opacity: 0, scale, x: "-50%", y: "-50%" }}
            animate={{
              opacity: 1,
              scale: optScale * 1.15,
              transition: {
                duration: 0.5,
                ease: [0.165, 0.84, 0.44, 1],
              },
            }}
          >
            <StyledTitle>{item.title || item.stage?.showTitle}</StyledTitle>
            {hasClick && (
              <ItemButton onClick={handleClick}>Bekijk nu</ItemButton>
            )}
          </TitleWrapper>
        )}

        <ItemWrapper>
          <ItemInnerWrapper
            ref={ref}
            onClick={
              (item.stage && item.stage.isLive === "live") ||
              (item.type === "videoSnippet" && item.youtubeId)
                ? handleClick
                : undefined
            }
          >
            {getItem(item)}
          </ItemInnerWrapper>
        </ItemWrapper>
      </Wrapper>
      {isFullscreenSnippet && (
        <>
          <CloseButtonTopLeft>
            <CloseButton onClick={handleCloseFullscreenSnippet} />
          </CloseButtonTopLeft>
        </>
      )}
    </>
  );
};

export default withTheme(LiveReportItem);

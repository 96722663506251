import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useMediaQuery } from "beautiful-react-hooks";
import { motion } from "framer-motion";

import { ROUTES } from "../routes";
import { useStore, STORE_ACTIONS } from "../hooks/store";
import { theme } from "../styling/theme";
import { lowlandsService } from "../services/lowlands-service";

import Layout from "../layouts/Challenge.jsx";
import RegisterPanel from "../components/RegisterPanel.jsx";
import { Title as TitleStyled } from "../components/interface/Title.jsx";
import { Lead } from "../components/interface/Lead.jsx";
import { Heading } from "../components/interface/Heading.jsx";
import { Button } from "../components/interface/Button.jsx";
import { Panel } from "../components/interface/Panel.jsx";
import { ScrollToTop } from "../components/ScrollToTop.jsx";

import ChallengeGridImgDesktop from "../static/images/challenge-img-grid-desktop.png";
import ChallengeGridImgMobile from "../static/images/challenge-img-grid-mobile.png";
import ToolkitImg from "../static/images/toolkit.png";

const ScrollBox = styled(motion.div)`
  display: flex;
`;
const Wrapper = styled.div`
  @media (${({ theme }) => theme.respondTo.tablet}) {
    /* 100vh - padding top of layout wrapper - height of the register panel */
    padding-bottom: calc((100vh - 26rem) - (30vh + 41.9rem));
  }
  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding-bottom: calc(85vh - (30vh + 31.5rem));
  }
`;

const Title = styled(TitleStyled)`
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  /* @media (${({ theme }) => theme.respondTo.desktop}) {
    max-width: calc(100% - 20rem);
  } */
`;

const Subtitle = styled(Heading)`
  margin-bottom: 1.6rem;
`;

const Paragraph = styled.div`
  margin-bottom: 8rem;
  @media (${({ theme }) => theme.respondTo.mobile}) {
    margin-right: 1rem;
  }

  p {
    font-size: 1.6rem;
    line-height: 150%;
    @media (${({ theme }) => theme.respondTo.tablet}) {
      font-size: 1.8rem;
    }
  }
`;

const ButtonHolder = styled.div`
  ${Button} {
    margin: 7rem auto 0;
    display: table;
  }
`;

const ChallengeImg = styled.img`
  margin-bottom: 3.5rem;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    width: 130%;
    max-width: none;
    margin-left: -10%;
  }
`;

const ToolkitBox = styled.div`
  position: relative;
  margin-bottom: 10rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin-bottom: 5rem;
  }

  ${Button} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    margin: 0;
    white-space: nowrap;
  }

  img {
    width: 140%;
    margin: 0px -15%;
    max-width: none;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      margin: 0 auto;
      width: 100%;
      max-width: 52rem;
    }
  }
`;

const Challenge = () => {
  const isMobile = useMediaQuery(`(${theme.respondTo.mobile})`);
  const { state, dispatch } = useStore();

  const onAnimationComplete = () => {
    dispatch({ type: STORE_ACTIONS.CHALLENGE_INTRO_SEEN });
  };
  return (
    <Layout>
      <ScrollBox
        key="challenge"
        initial={
          !state.challengeIntroSeen &&
          lowlandsService.isPreState() && { y: "100vh" }
        }
        animate={
          !state.challengeIntroSeen &&
          state.application.isNavigationVisible &&
          lowlandsService.isPreState() && {
            y: 0,
            transition: {
              delay: 1.75,
              duration: 1.75,
            },
          }
        }
        onAnimationComplete={onAnimationComplete}
      >
        <Wrapper>
          <ScrollToTop />
          <Panel background="blue">
            <Subtitle color="yellow" center>
              21 + 22 + 23 Augustus
            </Subtitle>
            <Title
              noShadow
              color="yellow"
              center
              style={{ marginBottom: "1.5rem" }}
            >
              LL20 <br />
              gaat door
            </Title>
            <Lead>
              Corona krijgt ons er niet onder en daarom vieren we Lowlands dit
              jaar in heel Nederland! Organiseer je eigen feest thuis, op de
              camping of in je stamkroeg. Maar wat je ook gaat doen, zorg in
              ieder geval dat jouw eigen thuisfestival 100% coronaproof is.
            </Lead>
            {isMobile && <RegisterPanel isStatic />}
            <Paragraph>
              <Heading color="yellow">Klaar voor?</Heading>
              <p>
                Op 22 en 23 augustus gaat Lowlands samen met 3FM en VPRO 3voor12
                los, live vanuit ons hoofdkwartier in de Melkweg. Op
                vrijdagavond 21 augustus beginnen alvast met de ArmadiLLow en op
                zaterdag en zondag gaan we helemaal om. Via 3FM.nl/Lowlands tune
                je het hele weekend in op een ramvolle live-uitzending met
                exclusieve livesessies, interviews en andere Lowlands TV-
                gekkigheid.
              </p>
            </Paragraph>
            <ChallengeImg
              src={isMobile ? ChallengeGridImgMobile : ChallengeGridImgDesktop}
            />
            <Paragraph>
              <Heading color="yellow">Maar er is meer</Heading>
              <p>
                We lanceren meerdere streams en interactieve Zoomboxen, waar je
                kunt aanhaken voor het maximale Lowlandsgevoel. Zo krijgen
                legendarische Lowlandsshows een eigen stream, net als pre-taped
                optredens van acts uit de line-up van LL20. En yoga dan?
                Literatuur, comedy en wetenschap? Virtueel afdansen in
                ArmadiLLow tot in de vroege uurtjes? Check, check, en check!
                Alleen de pannenkoeken moet je zelf bakken.
              </p>
              <p>
                Bovendien gaan de dj’s van 3FM op zaterdag en zondag het land in
                om jullie zelf georganiseerde Lowlandsfeestjes persoonlijk te
                checken en nog toffer te maken dan ze al zijn. Ze nemen
                spectaculaire verrassingen mee, dus beter hou je je camera en
                oordoppen klaar.
              </p>
              <ButtonHolder>
                <Button
                  as={Link}
                  to={ROUTES.SIGN_UP_FORM.URL}
                  background="purple"
                  color="white"
                >
                  Jouw LLOWParty aanmelden
                </Button>
              </ButtonHolder>
            </Paragraph>
            <Paragraph>
              <Heading color="yellow">Maak zelf je Lowlands</Heading>
              <p>
                We zijn benieuwd wat jullie plannen zijn, dus laat ons vooral
                weten hoe jouw Lowlands-party er straks uit gaat zien via #LL20
                #3FM en #LLthuis. Anderhalvemeter-Alpha’s, handgelcocktailbars,
                mondkapjesmeezingfestijnen, een silent disco met DJ Van Dissel &
                MC Irma? Laat maar zien dan! Uiteraard wel allemaal 100%
                coronaproof!
              </p>
            </Paragraph>
            <ToolkitBox>
              <img src={ToolkitImg} alt="Lowlands Toolkit" />
              <Button
                as={Link}
                to={ROUTES.TOOLKIT.URL}
                background="green"
                color="white"
              >
                Download de LL Toolkit
              </Button>
            </ToolkitBox>
            <p>Superspread the LLove!</p>
            <Heading color="yellow">#LL20 #3FM #LLthuis</Heading>
          </Panel>
        </Wrapper>
        {!isMobile && <RegisterPanel />}
      </ScrollBox>
    </Layout>
  );
};

export default Challenge;

import styled from "styled-components";

export const Panel = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  max-width: ${({ theme }) => theme.maxPanelWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  color: ${({ theme, color }) => (color ? theme[color] : theme.white)};
  background: ${({ theme, background }) =>
    background ? theme[background] : theme.purple};
  padding: 5rem 1.5rem 10rem;
  transition: height 0.4s ${({ theme }) => theme.transition};
  ${({ fullFixed }) =>
    fullFixed && `height: calc(100vh - 15vh); padding-bottom: 6.5rem;`}

  @media (${({ theme }) => theme.respondTo.tablet}) {
    padding: 5rem 4.5rem 12rem;
    ${({ fullFixed }) => fullFixed && `height: 85vh; padding-bottom: 7rem;`}
  }
`;

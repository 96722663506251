import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

import { useStore, STORE_ACTIONS } from "../hooks/store";
import { BACKGROUND_VIDEOS } from "../constants/media";

import { ROUTES } from "../routes";
import { Title } from "../components/interface/Title.jsx";
import { Button } from "../components/interface/Button.jsx";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`;

const ErrorContainer = styled.div`
  max-width: 67rem;
  margin: 3.6rem;
  text-align: center;
  padding: 2rem;
  background: ${({ theme }) => theme.yellow};
  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: 3.6rem;
  }
  ${Title} {
    &:nth-child(1) {
      font-size: 7.2rem;
      @media (${({ theme }) => theme.respondTo.tablet}) {
        font-size: 10rem;
      }
      margin-bottom: 2.4rem;
    }
    &:nth-child(2) {
      font-size: 3rem;
      @media (${({ theme }) => theme.respondTo.tablet}) {
        font-size: 5rem;
      }
    }
  }
  ${Button} {
    margin-top: 1.6rem;
    @media (${({ theme }) => theme.respondTo.tablet}) {
      margin-top: 3.6rem;
    }
  }
`;

const ErrorPage = () => {
  const { dispatch } = useStore();

  useEffect(() => {
    dispatch({
      type: STORE_ACTIONS.SET_VIDEO_BACKGROUND,
      name: BACKGROUND_VIDEOS.RED.NAME,
    });
  }, [dispatch]);

  return (
    <Wrapper>
      <Helmet>
        <title>Pagina niet gevonden</title>
        <meta name="ROBOTS" content="NOINDEX" />
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <ErrorContainer>
        <Title color="red">404</Title>
        <Title as="h2" color="red">
          Leuk dat je er bent, maar er valt hier niets te beleven.
        </Title>
        <Button as={Link} to={ROUTES.HOME.URL} background="red" color="yellow">
          Terug naar home
        </Button>
      </ErrorContainer>
    </Wrapper>
  );
};

export default ErrorPage;

import React from "react";
import styled from "styled-components";

import { useStore } from "../hooks/store";

const BackgroundVideoStyled = styled.video`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
  background: ${({ theme, background }) => theme[background] || theme.purple};
`;

export const BackgroundVideo = () => {
  const { state } = useStore();
  return (
    <BackgroundVideoStyled
      autoPlay
      playsInline
      muted
      loop
      key={state.backgroundVideoPath}
      background={state.backgroundVideoColor}
      preload="auto"
    >
      <source src={`${state.backgroundVideoPath}#t=0.1`} type="video/mp4" />
      <source src={state.backgroundVideoPath} type="video/mp4" />
    </BackgroundVideoStyled>
  );
};

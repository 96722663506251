export const CDN_URL = "https://content.omroep.nl/3fm/lowlands/2020/static";

export const BACKGROUND_VIDEOS = {
  FREE_UNITED: {
    NAME: "FREE_UNITED",
    FILENAME: "free_united.mp4",
    COLOR: "blue",
  },
  RED: {
    NAME: "RED",
    FILENAME: "red.mp4",
    COLOR: "yellow",
  },
  GREEN: {
    NAME: "GREEN",
    FILENAME: "green.mp4",
    COLOR: "green",
  },
  BLUE: {
    NAME: "BLUE",
    FILENAME: "blue.mp4",
    COLOR: "turquoise",
  },
};

import React, { memo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

import { EASINGS } from "../../constants/easings";

const ZOOM_CTA_TOTAL_Y_PX = 150;
const ZOOM_PANEL_TOTAL_Y_PX = 350;

const MotionZoomPanel = styled(motion.div)`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2rem 2.4rem;
  background-color: ${({ theme }) => theme.yellow};
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    right: auto;
    bottom: 2rem;
    left: 2rem;
    padding: 2.4rem 2.8rem;
    width: 50rem;
  }
`;

const ZoomPanelText = styled.p`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 2rem;
  color: ${({ theme }) => theme.blue};
  text-align: center;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 2.8rem;
  }
`;

const MotionZoomButton = styled(motion.a)`
  display: inline-block;
  background-color: ${({ theme }) => theme.lightBlue};
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.6rem;
  text-align: center;
  height: 4rem;
  line-height: 4rem;
  padding: 0 2rem;
  @media (${({ theme }) => theme.respondTo.mobile}) {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 1.8rem;
    height: 5rem;
    line-height: 5rem;
  }
`;

const MotionZoomPanelButton = styled(MotionZoomButton)`
  min-width: 14rem;
  margin-top: 1.6rem;
  @media (${({ theme }) => theme.respondTo.mobile}) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin-top: 2rem;
  }
`;

const MotionZoomCta = styled(MotionZoomButton)`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 2;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    left: 3rem;
    bottom: 3rem;
  }
`;

export const ZoomControls = memo(
  ({ showZoomPanel, showZoomCta, zoomUrl, isPlaying }) => {
    return (
      <AnimatePresence>
        {showZoomPanel && (
          <MotionZoomPanel
            key="motionZoomPanel"
            initial={{
              y: ZOOM_PANEL_TOTAL_Y_PX,
            }}
            animate={{
              y: isPlaying ? 0 : ZOOM_PANEL_TOTAL_Y_PX,
              transition: {
                duration: 1,
                ease: EASINGS.EASE_OUT_EXPO,
              },
            }}
          >
            <ZoomPanelText>
              Deze stream is alleen te volgen via zoom. Klikken is meemaken!
            </ZoomPanelText>
            <MotionZoomPanelButton href={zoomUrl} target={"_blank"}>
              Doe mee
            </MotionZoomPanelButton>
          </MotionZoomPanel>
        )}

        {showZoomCta && (
          <MotionZoomCta
            key="motionZoomCta"
            href={zoomUrl}
            target={"_blank"}
            initial={{
              y: ZOOM_CTA_TOTAL_Y_PX,
            }}
            animate={{
              y: isPlaying ? 0 : ZOOM_CTA_TOTAL_Y_PX,
              transition: {
                duration: 1,
                ease: EASINGS.EASE_OUT_EXPO,
              },
            }}
          >
            Doe mee via Zoom
          </MotionZoomCta>
        )}
      </AnimatePresence>
    );
  }
);

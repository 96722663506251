import React from "react";
import styled from "styled-components";

const Img = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;

const Image = ({ url, ...rest }) => {
  return <Img src={url} {...rest} />;
};

export default Image;

import React, { useState, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import "smoothscroll-for-websites/SmoothScroll";

import { IS_DEV } from "./constants/environment";
import { ROUTES } from "./routes";
import { useStore, STORE_ACTIONS } from "./hooks/store";
import { lowlandsService } from "./services/lowlands-service";
import { loadExternalScripts } from "./utils/external-scripts";
import { isHomeOrStreamDetailRoute } from "./utils/helpers";
import GlobalStyle from "./styling/GlobalStyle";

import Layout from "./layouts/Default";
import Challenge from "./pages/Challenge";
import Artist from "./pages/Artist";
import Program from "./pages/Program";
import Live from "./pages/Live";
import NowLive from "./pages/NowLive";
import Loader from "./components/Loader";
import Register from "./pages/Register";
import Toolkit from "./pages/Toolkit";
import Success from "./pages/Success";
import ErrorPage from "./pages/Error";
import InfoPage from "./pages/InfoPage";
import BeforeStateBanner from "./components/BeforeStateBanner.jsx";
import AfterStateBanner from "./components/AfterStateBanner.jsx";

const LOADER_EXIT_DELAY_PRODUCTION_MS = 3000;
const LOADER_EXIT_DELAY_DEVELOPMENT_MS = 0;

const App = () => {
  const { state, dispatch } = useStore();
  const [appIsReady, setAppIsReady] = useState(false);
  const location = useLocation();
  const disablePageOverflow =
    isHomeOrStreamDetailRoute({ path: location.pathname }) &&
    !lowlandsService.isPreState();

  const handleLoaderAnimationEnd = () => {
    // Show the navigation if the first route is not home, or the current state is is pre state
    if (
      !isHomeOrStreamDetailRoute({ path: location.pathname }) ||
      lowlandsService.isPreState()
    ) {
      dispatch({ type: STORE_ACTIONS.APPLICATION.SHOW_NAVIGATION });
      dispatch({ type: STORE_ACTIONS.APPLICATION.SHOW_PIP });
    }
  };

  useEffect(() => {
    setTimeout(() => setAppIsReady(true), 500);
  }, []);

  useEffect(() => {
    setTimeout(
      () => {
        dispatch({ type: STORE_ACTIONS.APPLICATION.IS_LOADED });
      },
      IS_DEV
        ? LOADER_EXIT_DELAY_DEVELOPMENT_MS
        : LOADER_EXIT_DELAY_PRODUCTION_MS
    );

    // If the delay is too short the animation end event won't fire, so we have to fire it manually.
    if (IS_DEV && LOADER_EXIT_DELAY_DEVELOPMENT_MS < 100) {
      handleLoaderAnimationEnd();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadExternalScripts();
  }, [appIsReady]);

  if (!appIsReady) return "";

  return (
    <main>
      <GlobalStyle disablePageOverflow={disablePageOverflow} />

      <Layout
        style={
          !state.application.isLoaded
            ? { height: "100vh", overflow: "hidden" }
            : {}
        }
      >
        {lowlandsService.isBeforeState() && (
          <AnimatePresence exitBeforeEnter>
            {!state.hideScrollbar && <BeforeStateBanner />}
          </AnimatePresence>
        )}

        {lowlandsService.isAfterState() && (
          <AnimatePresence exitBeforeEnter>
            {!state.hideScrollbar && <AfterStateBanner />}
          </AnimatePresence>
        )}
        <AnimatePresence exitBeforeEnter>
          {!state.application.isLoaded && (
            <motion.div
              key={state.application.isLoaded}
              exit={{
                clipPath: "inset(0 50% 0 50%)",
                transition: {
                  duration: 1.25,
                  ease: [0.165, 0.84, 0.44, 1],
                },
              }}
              onAnimationComplete={handleLoaderAnimationEnd}
            >
              <Loader />
            </motion.div>
          )}
        </AnimatePresence>
        <Switch>
          <Route
            exact
            path={[
              ROUTES.HOME.URL,
              lowlandsService.isLiveState()
                ? ROUTES.STREAM_DETAIL.ROUTER_PATH
                : "",
              lowlandsService.isLiveState() || lowlandsService.isBeforeState()
                ? ROUTES.VIDEO_DETAIL.ROUTER_PATH
                : "",
            ]}
          >
            {lowlandsService.isPreState() ? <Challenge /> : <Live />}
          </Route>
          {!lowlandsService.isPreState() && (
            <Route exact path={ROUTES.CHALLENGE.URL}>
              <Challenge />
            </Route>
          )}
          {!lowlandsService.isPreState() && !lowlandsService.isBeforeState() && (
            <Route exact path={ROUTES.NOWLIVE.URL}>
              <NowLive />
            </Route>
          )}
          <Route exact path={ROUTES.PROGRAM.URL}>
            <Program />
          </Route>
          <Route exact path={ROUTES.PROGRAM_DETAIL.ROUTER_PATH}>
            <Artist />
          </Route>
          <Route exact path={ROUTES.SIGN_UP_FORM.URL}>
            <Register />
          </Route>
          <Route exact path={ROUTES.SIGN_UP_SUCCES.URL}>
            <Success />
          </Route>
          <Route exact path={ROUTES.TOOLKIT.URL}>
            <Toolkit />
          </Route>
          <Route exact path={[ROUTES.ABOUT.URL, ROUTES.PARTY_SAFE.URL]}>
            <InfoPage />
          </Route>
          <Route>
            <ErrorPage />
          </Route>
        </Switch>
      </Layout>
    </main>
  );
};

export default App;

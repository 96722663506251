import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import Ticker from "react-ticker";

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: ${({ theme }) => theme.blue};
  z-index: 2;
`;

const Title = styled.h3`
  white-space: nowrap;
  span {
    display: inline-block;
    background: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.yellow};
    &:nth-of-type(even) {
      background: ${({ theme }) => theme.yellow};
      color: ${({ theme }) => theme.blue};
    }
    font-size: 2.4rem;
    padding: 1.2rem;
  }
`;

const ScrollBar = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoaded(true), 1000);
    return () => clearTimeout(timeout);
  }, []);

  const transition = { duration: 1, ease: [0.165, 0.84, 0.44, 1], delay: 0.2 };
  return (
    <Wrapper exit={{ y: "-100%", transition }}>
      <Title>
        {isLoaded && (
          <Ticker direction="toLeft">
            {({ index }) => (
              <>
                <span>Lowlands free:united 21 + 22 + 23 augustus</span>
                <span>Start 21 augustus vanaf 17:00</span>
                <span>Lowlands free:united 21 + 22 + 23 augustus</span>
                <span>Start 21 augustus vanaf 17:00</span>
              </>
            )}
          </Ticker>
        )}
      </Title>
    </Wrapper>
  );
};

export default ScrollBar;

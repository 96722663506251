import React from "react";
import ReactDOM from "react-dom";
import smoothscroll from "smoothscroll-polyfill";
import { ThemeProvider } from "styled-components";
import { HelmetProvider } from "react-helmet-async";
import { Router } from "react-router-dom";
import { StateProvider } from "./store/store";
import { createBrowserHistory } from "history";

import { theme } from "./styling/theme";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <StateProvider>
          <Router history={history}>
            <App />
          </Router>
        </StateProvider>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

/*
Enables scrollTo methods on window and elements.
See: https://github.com/iamdustan/smoothscroll
 */
smoothscroll.polyfill();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

import { apiLowlands } from "../apis/api-lowlands";
import { ROUTES } from "../routes";
import ErrorPage from "../pages/Error.jsx";
import { BACKGROUND_VIDEOS } from "../constants/media";

import Layout from "../layouts/Panel.jsx";
import { Title } from "../components/interface/Title.jsx";
import { Lead } from "../components/interface/Lead.jsx";
import { PageLoader } from "../components/PageLoader";
import { Blocks } from "../components/Blocks";
import { ScrollToTop } from "../components/ScrollToTop";

const StyledTitle = styled(Title)`
  text-align: center;
  margin: 0 0 2.4rem;
`;

const InfoPage = () => {
  const location = useLocation();
  const [endpoint, setEndpoint] = useState(false);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(false);

  const getApiEndpointBySlug = (slug) => {
    const route = Object.keys(ROUTES).find(
      (route) => ROUTES[route].URL === slug
    );
    setEndpoint(ROUTES[route].API_ENDPOINT);
  };

  const getPageContent = async () => {
    const { data, status } = await apiLowlands.getInfoPage({ slug: endpoint });
    if (status) setError(true);
    setContent(data);
  };

  useEffect(() => {
    getApiEndpointBySlug(location.pathname);
  }, [location]);

  useEffect(() => {
    if (!endpoint) return;
    getPageContent();
  }, [endpoint]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <ErrorPage />;
  if (!content)
    return (
      <Layout>
        <PageLoader />
      </Layout>
    );

  return (
    <Layout
      background="purple"
      small
      backgroundVideoName={BACKGROUND_VIDEOS.GREEN.NAME}
    >
      <Helmet>
        <title>{content.title} | Lowlands Free:United</title>
        <meta name="description" content={content.intro_stripped} />
      </Helmet>
      <ScrollToTop />
      <StyledTitle color="yellow" noShadow>
        {content.title}
      </StyledTitle>
      <Lead as="div" dangerouslySetInnerHTML={{ __html: content.intro }} />
      {content.blocks && <Blocks blocks={content.blocks} />}
    </Layout>
  );
};

export default InfoPage;

import styled from "styled-components";
import { Link } from "react-router-dom";

export const Back = styled(Link)`
  appearance: none;
  border: none;
  background: none;
  padding: 1rem;
  font-size: 1.4rem;
  line-height: 135%;
  position: absolute;
  top: 0;
  left: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.white};
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import Ticker from "react-ticker";

const Wrapper = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: ${({ theme }) => theme.white};
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.purple};

  p {
    display: flex;
    white-space: nowrap;
    font-size: 2.4rem;
    padding: 1rem 0;

    span {
      display: block;
      margin: 0 1rem;
    }
  }
`;

const ScrollBar = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoaded(true), 1000);
    return () => clearTimeout(timeout);
  }, []);

  const transition = { duration: 1, ease: [0.165, 0.84, 0.44, 1] };

  return (
    <Wrapper exit={{ y: "100%", transition }}>
      <Title>
        {isLoaded && (
          <Ticker direction="toRight">
            {({ index }) => (
              <p>
                <span>Scroll</span> <span>Scroll</span> <span>Scroll</span>{" "}
                <span>Scroll</span> <span>Scroll</span> <span>Scroll</span>{" "}
                <span>Scroll</span> <span>Scroll</span> <span>Scroll</span>
                <span>Scroll</span> <span>Scroll</span> <span>Scroll</span>{" "}
                <span>Scroll</span> <span>Scroll</span> <span>Scroll</span>{" "}
                <span>Scroll</span> <span>Scroll</span> <span>Scroll</span>
                <span>Scroll</span> <span>Scroll</span> <span>Scroll</span>{" "}
                <span>Scroll</span> <span>Scroll</span>
              </p>
            )}
          </Ticker>
        )}
      </Title>
    </Wrapper>
  );
};

export default ScrollBar;

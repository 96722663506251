import React, { useState, useEffect, useCallback } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import styled from "styled-components";
import normalizeWheel from "normalize-wheel";

import { BACKGROUND_VIDEOS } from "../constants/media";
import { ROUTES } from "../routes";
import { useStore, STORE_ACTIONS } from "../hooks/store";
import { apiLowlands } from "../apis/api-lowlands";

import LiveReportItem from "../components/LiveReportItem";
import Stream from "../pages/Stream";
import ScrollBar from "../components/ScrollBar";

import { PageLoader } from "../components/PageLoader";

const SCROLL_THRESHOLD_FOR_INTERFACE_VISIBILITY_PX =
  window.innerWidth > 1024 ? 200 : 1000;

const MOUSE_SPEED = 3;
const SMOOTH_MOUSE_SPEED = 1.5;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
`;

const Live = () => {
  const location = useLocation();
  const { state, dispatch } = useStore();
  const [items, setItems] = useState(null);
  const [deltaY, setDeltaY] = useState(1);
  const [touchStart, setTouchStart] = useState(0);
  const [totalScrolled, setTotalScrolled] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleWheel = (ev) => {
    if (location.pathname !== ROUTES.HOME.URL) return;
    const e = normalizeWheel(ev);

    let delta;
    const step = Math.abs(deltaY - e.pixelY);
    // Check if smooth mouse or normal mouse
    if (step > 50) {
      delta = e.pixelY / MOUSE_SPEED;
    } else {
      delta = e.pixelY / SMOOTH_MOUSE_SPEED;
    }
    delta = delta + Math.random() * 0.001;
    setDeltaY(delta);

    if (totalScrolled < SCROLL_THRESHOLD_FOR_INTERFACE_VISIBILITY_PX) {
      setTotalScrolled(totalScrolled + e.pixelY);
    }
  };

  const handleTouchStart = (e) => {
    if (location.pathname !== ROUTES.HOME.URL) return;
    setTouchStart(e.changedTouches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (location.pathname !== ROUTES.HOME.URL) return;
    const y = e.changedTouches[0].clientY;
    const deltaY = (touchStart - y) * 4;
    setDeltaY(deltaY);
    setTouchStart(y);

    if (totalScrolled < SCROLL_THRESHOLD_FOR_INTERFACE_VISIBILITY_PX) {
      setTotalScrolled(totalScrolled + deltaY);
    }
  };

  useEffect(() => {
    if (
      Math.abs(totalScrolled) >= SCROLL_THRESHOLD_FOR_INTERFACE_VISIBILITY_PX
    ) {
      dispatch({ type: STORE_ACTIONS.APPLICATION.SHOW_NAVIGATION });
      dispatch({ type: STORE_ACTIONS.APPLICATION.SHOW_PIP });
    }
  }, [totalScrolled, dispatch]);

  const getFeed = useCallback(async () => {
    const feedData = await apiLowlands.getFeed();
    setItems(
      feedData.filter(
        (feedItem) => !feedItem.stage || feedItem.stage.isLive !== "offline"
      )
    );
  }, []);

  useEffect(() => {
    if (totalScrolled > 0) return;
    const timeout = setTimeout(() => {
      setDeltaY(100);
      setTotalScrolled(100);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [totalScrolled]);

  useEffect(() => {
    getFeed();
    if (!state.hideScrollbar) return;
    dispatch({
      type: STORE_ACTIONS.SET_VIDEO_BACKGROUND_COLOR,
      name: "purple",
    });
    dispatch({
      type: STORE_ACTIONS.SET_VIDEO_BACKGROUND,
      name: BACKGROUND_VIDEOS.GREEN.NAME,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!items)
    return (
      <Wrapper>
        <PageLoader big={true} />
      </Wrapper>
    );

  return (
    <Wrapper
      onWheel={(e) => handleWheel(e)}
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchMove={(e) => handleTouchMove(e)}
    >
      <LiveReportItem
        key={activeIndex}
        setActiveIndex={setActiveIndex}
        activeIndex={activeIndex}
        totalItems={items.length}
        item={items[activeIndex]}
        deltaY={deltaY}
      />
      <AnimatePresence>
        {!state.hideScrollbar && !state.application.isNavigationVisible && (
          <ScrollBar />
        )}
      </AnimatePresence>
      <Route
        render={({ location }) => (
          <AnimatePresence initial={false}>
            <Switch location={location} key={location.pathname}>
              <Route
                exact
                path={[
                  ROUTES.STREAM_DETAIL.ROUTER_PATH,
                  ROUTES.VIDEO_DETAIL.ROUTER_PATH,
                ]}
              >
                <Stream />
              </Route>
            </Switch>
          </AnimatePresence>
        )}
      />
    </Wrapper>
  );
};

export default React.memo(Live);

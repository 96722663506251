import { CDN_URL } from "../constants/media";
import { ROUTES } from "../routes";

export const getScaleValue = (scale, delta) => {
  const value = scale + delta / 650;
  return value <= 0 ? 0 : value;
};

export const getMaskValue = (scrolledAfterFullscreen, isForward, deltaY) => {
  const delta = isForward
    ? deltaY
    : !isForward && deltaY < 0
    ? Math.abs(deltaY)
    : !isForward && deltaY > 0
    ? -deltaY
    : deltaY;
  return scrolledAfterFullscreen + delta;
};

const sizes = ["mobile-opt", "tablet", "desktop", "desktop_big"];
export const getDeviceSize = () => {
  const width = window.innerWidth;
  if (width > 1440) {
    return sizes[3];
  }
  if (width > 768) {
    return sizes[2];
  }
  if (width > 480) {
    return sizes[1];
  }
  return sizes[0];
};

export const getBackgroundVideoPath = ({ filename }) => {
  return `${CDN_URL}/${getDeviceSize()}/${filename}`;
};

export const hexToRgba = (hex, alpha, asCSS = true) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return null;

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  const a = alpha;

  return asCSS ? `rgba(${r}, ${g}, ${b}, ${a})` : { r, g, b, a };
};

export const getYoutubeVideoUrl = ({ videoId }) => {
  const youtubeLiveVideoId = videoId.startsWith("youtube_")
    ? videoId.split("youtube_")[1]
    : videoId;

  return `https://www.youtube.com/embed/${youtubeLiveVideoId}`;
};

export const isHomeOrStreamDetailRoute = ({ path }) => {
  return path === ROUTES.HOME.URL || path.startsWith(ROUTES.STREAM_DETAIL.URL);
};

export const getRandomFloat = ({ min, max }) => {
  return Math.random() * (max - min + 1) + min;
};

export const getRandomInt = ({ min, max }) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

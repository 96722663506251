import styled, { css } from "styled-components";

export const Button = styled.button`
  appearance: none;
  border: none;
  background: none;
  outline: none;
  display: inline-block;
  font-family: ${({ theme }) => theme.fontFamily};
  padding: 1em;
  font-size: 1.8rem;
  cursor: pointer;
  background: ${({ theme, background }) =>
    background ? theme[background] : theme["purple"]};
  color: ${({ theme, color }) => (color ? theme[color] : theme.white)};
  ${({ center }) =>
    center &&
    css`
      display: table;
      margin-left: auto;
      margin-right: auto;
    `}
  ${({ size }) =>
    size &&
    size === "small" &&
    css`
      font-size: 1.6rem;
    `}
`;

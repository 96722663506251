import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { apiLowlands } from "../apis/api-lowlands";
import { dateTimeService } from "../services/date-time-service";
import { BACKGROUND_VIDEOS } from "../constants/media";

import Layout from "../layouts/Panel.jsx";
import ErrorPage from "../pages/Error.jsx";

import { Title as TitleStyled } from "../components/interface/Title.jsx";
import { Button } from "../components/interface/Button.jsx";
import { AspectRatioBox } from "../components/interface/AspectRatioBox.jsx";
import { Back } from "../components/interface/Back.jsx";
import { PageLoader } from "../components/PageLoader";

const Container = styled.div`
  max-width: ${({ theme }) => theme.maxContentWidth};
  width: 100%;
  margin: 0 auto;
`;

const Title = styled(TitleStyled).attrs({
  center: true,
})`
  margin-bottom: 1.6rem;
  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin-bottom: 2rem;
  }
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.fontFamily};
  margin: 0 auto;
  text-align: center;
  font-size: 1.6rem;
`;

const TimeslotWrapper = styled.div`
  margin-bottom: 1.4rem;
  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin-bottom: 3rem;
  }
`;

const Timeslot = styled(Text)`
  font-size: 1.8rem;
  @media (${({ theme }) => theme.respondTo.tablet}) {
    font-size: 2.4rem;
  }
`;

const Subtitle = styled(Text)`
  font-size: 1.4rem;
  margin-bottom: 2rem;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    font-size: 1.6rem;
    margin-bottom: 2.4rem;
  }
`;

const Bio = styled.div`
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  margin: 0 auto 4rem;

  p {
    margin-top: 0;
    margin-bottom: 1.6rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    @media (${({ theme }) => theme.respondTo.desktop}) {
      font-size: 1.8rem;
    }
  }
`;

const ImageHolder = styled.div`
  display: table;
  margin: 3rem auto;
  max-width: 72rem;
  width: 100%;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.12);

  @media (${({ theme }) => theme.respondTo.desktop}) {
    box-shadow: 0 0.6rem 0.9rem rgba(0, 0, 0, 0.12);
  }
`;

const Artist = () => {
  const { slug } = useParams();
  const [artist, setArtist] = useState(null);
  const [error, setError] = useState(false);

  const getArtist = async () => {
    const data = await apiLowlands.getArtist({ slug });
    if (data.status) setError(true);
    setArtist(data);
  };

  useEffect(() => {
    getArtist();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return <ErrorPage />;

  if (!artist)
    return (
      <Layout
        background="green"
        color="black"
        backgroundVideo={BACKGROUND_VIDEOS.GREEN.NAME}
      >
        <Back
          as={Link}
          to={{ pathname: "/programma", state: { section: "lineUp" } }}
        >
          {"<"} Terug
        </Back>
        <PageLoader />
      </Layout>
    );

  return (
    <Layout
      background="green"
      color="black"
      backgroundVideoName={BACKGROUND_VIDEOS.GREEN.NAME}
    >
      <Helmet>
        <title>{artist.title} | Lowlands Free:United </title>
        <meta
          name="description"
          content={artist.bio?.replace(/(<([^>]+)>)/gi, "") || artist.title}
        />
      </Helmet>
      <Container>
        <Back
          as={Link}
          to={{ pathname: "/programma", state: { section: "lineUp" } }}
        >
          {"<"} Terug
        </Back>
        {artist.shows.length > 0 && (
          <TimeslotWrapper>
            {artist.shows.map((show) => {
              const startDate = dateTimeService.getDateFromUnix({
                unix: show.startDateTime,
              });
              const endDate = dateTimeService.getDateFromUnix({
                unix: show.endDateTime,
              });
              return (
                <Timeslot key={show.id}>
                  {startDate.format("dddd")}, {show.stageTitle} -{" "}
                  {startDate.format("HH:mm")} - {endDate.format("HH:mm")}
                </Timeslot>
              );
            })}
          </TimeslotWrapper>
        )}
        <Title>{artist.title}</Title>
        {artist.subTitle && <Subtitle>{artist.subTitle}</Subtitle>}
        <Bio dangerouslySetInnerHTML={{ __html: artist.bio }} />

        <ImageHolder>
          <AspectRatioBox ratioHeight="100%" background="purple">
            <img src={artist.imgUrl} alt={artist.title} />
          </AspectRatioBox>
        </ImageHolder>
        {artist.spotifyLink && (
          <Button
            as="a"
            href={artist.spotifyLink}
            target="_blank"
            rel="noopener"
            background="purple"
            color="white"
            center
          >
            Luister op spotify
          </Button>
        )}
      </Container>
    </Layout>
  );
};

export default Artist;

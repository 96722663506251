import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import { getRandomInt, getRandomFloat } from "../utils/helpers";

import HearthAnim01 from "../static/images/hearth_anim_01.png";
import HearthAnim02 from "../static/images/hearth_anim_02.png";
import HearthAnim03 from "../static/images/hearth_anim_03.png";
import HearthAnim04 from "../static/images/hearth_anim_04.png";

const HEARTH_ICON_IMAGES = [
  HearthAnim01,
  HearthAnim02,
  HearthAnim03,
  HearthAnim04,
];

const DURATION_MIN_MAX_S = {
  min: 2,
  max: 2.5,
};

const ICONS_MIN_MAX_AMOUNT = {
  min: 18,
  max: 22,
};

const ICONS_MIN_MAX_SIZE_PERC = {
  min: 0.075,
  max: 0.1,
};

const LikeImage = styled(motion.div)`
  position: absolute;
  top: ${({ ranOffsetTop }) => ranOffsetTop}px;
  left: ${({ ranOffsetLeft }) => ranOffsetLeft}px;
  width: ${({ ranWidth }) => ranWidth}px;
  height: ${({ ranWidth }) => ranWidth}px;
  img {
    width: 100%;
  }
`;

const getRandomOffsets = ({ width }) => {
  const offsetWidth = getRandomInt({
    min: width * ICONS_MIN_MAX_SIZE_PERC.min,
    max: width * ICONS_MIN_MAX_SIZE_PERC.max,
  });
  return {
    width: offsetWidth,
    left: getRandomInt({ min: 0, max: width - offsetWidth }),
    top: getRandomInt({ min: -1 * offsetWidth, max: -1 * offsetWidth * 2 }),
  };
};

const getRandomImage = () => {
  return HEARTH_ICON_IMAGES[getRandomInt({ min: 0, max: 3 })];
};

const getRandomHearthIcons = ({ animationWidth, animationHeight }) => {
  const amount = getRandomInt(ICONS_MIN_MAX_AMOUNT);
  const icons = [];
  for (let i = 0; i < amount; i++) {
    const src = getRandomImage();
    const { width, top, left } = getRandomOffsets({
      width: animationWidth,
    });
    icons.push(
      <LikeImage
        ranWidth={width}
        ranOffsetTop={top}
        ranOffsetLeft={left}
        key={`hearthAnim-${i}`}
        initial={{
          y: top,
        }}
        animate={{
          y: Math.abs(top) + animationHeight,
          transition: {
            duration: getRandomFloat(DURATION_MIN_MAX_S),
            ease: "easeIn",
          },
        }}
        exit={false}
      >
        <img src={src} alt="Hearth icon" aria-hidden />
      </LikeImage>
    );
  }
  return icons;
};

export const LikeAnimation = ({ animationHeight, animationWidth, onDone }) => {
  useEffect(() => {
    if (!onDone) return;
    setTimeout(onDone, (DURATION_MIN_MAX_S.max + 1) * 1000);
    return () => clearTimeout(onDone);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <AnimatePresence>
      {getRandomHearthIcons({ animationHeight, animationWidth })}
    </AnimatePresence>
  );
};

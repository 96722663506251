import { IS_DEV } from "../constants/environment";

export const loadExternalScripts = () => {
  if (IS_DEV) return;

  // AT Internet tellers
  const script = document.createElement("script");
  setTimeout(() => {
    script.src = "//assets.npo-data.nl/tag/v3/npotag.js";
    script.async = true;
    document.body.appendChild(script);

    window.addEventListener("npoReady", function () {
      window.NPO.page();
    });
  }, 1000);

  // Include cookie script
  (function () {
    var ccmWrapperElement = document.createElement("script");
    var organisation = "NPO";
    var site = "npo3fm.nl";
    var ccmWrapperVersion = "v1.1";
    var ccmDomain = "https://ccm.npo.nl";
    ccmWrapperElement.type = "text/javascript";
    ccmWrapperElement.crossOrigin = "anonymous";
    ccmWrapperElement.src =
      ccmDomain +
      "/sites/" +
      organisation +
      "/" +
      site +
      "/ccm-wrapper_" +
      ccmWrapperVersion +
      ".js";
    ccmWrapperElement.async = true;
    ccmWrapperElement.defer = ccmWrapperElement.async;
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(ccmWrapperElement, s);

    window.addEventListener(
      "CCM_Wrapper_ready",
      function (e) {
        setTimeout(() => {
          window.ccmWrapper.init();
        }, 4000);
      },
      false
    );
  })();
};

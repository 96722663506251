import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowSVG } from "../../static/icons/arrow.svg";

import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

export const SliderStyled = styled.div.attrs({
  className: "keen-slider",
})`
  cursor: grab;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin: ${({ withNavigation }) => withNavigation && `4rem 0 0`};
  }
`;

export const Slide = styled.div.attrs({
  className: "keen-slider__slide",
})``;

const SliderNavItems = styled.div`
  position: absolute;
  left: 50%;
  top: 17rem;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    top: 20rem;
  }

  @media (${({ theme }) => theme.respondTo.mobile}) {
    display: none;
  }
`;

const SliderNav = styled.button`
  width: 4rem;
  height: 4rem;
  background: white;
  border-radius: 50%;
  border: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.25s;
  margin: 0 0.5rem;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    transform: ${({ right }) => !right && `rotate(180deg)`};
    width: 1.75rem;
    height: 1.75rem;

    @media (${({ theme }) => theme.respondTo.tablet}) {
      width: 2rem;
      height: 2rem;
    }

    .arrow {
      stroke: ${({ theme }) => theme.purple};
    }
  }
`;

export const Slider = ({
  withNavigation,
  children,
  breakpoints,
  spaceBetween,
  moveToSlide = null,
  setMoveToSlide = null,
  mode = "free",
  ...rest
}) => {
  const [sliderRef, slider] = useKeenSlider({
    spacing: spaceBetween,
    breakpoints,
    mode,
    slideChanged: (slider) => {
      if (setMoveToSlide === null) return;
      setMoveToSlide(slider.details().absoluteSlide);
    },
  });

  useEffect(() => {
    if (
      moveToSlide === null ||
      !slider ||
      slider.details().absoluteSlide === moveToSlide
    )
      return;
    slider.moveToSlide(moveToSlide);
  }, [moveToSlide, slider]);

  return (
    <>
      {withNavigation && (
        <SliderNavItems>
          <SliderNav onClick={() => slider.prev()}>
            <ArrowSVG />
          </SliderNav>
          <SliderNav right onClick={() => slider.next()}>
            <ArrowSVG />
          </SliderNav>
        </SliderNavItems>
      )}
      <SliderStyled withNavigation={withNavigation} ref={sliderRef} {...rest}>
        {children}
      </SliderStyled>
    </>
  );
};

import React, { createContext, useReducer } from "react";
import { BACKGROUND_VIDEOS } from "../constants/media.js";
import { getBackgroundVideoPath } from "../utils/helpers";

const initialState = {
  application: {
    isLoaded: false, // Preloading is done, start any page or other app logic or animation.
    isNavigationVisible: false,
    isPipVideoVisible: false, // The picture-in-picture live video.
  },
  videoIndex: 0,
  backgroundVideoColor: BACKGROUND_VIDEOS.FREE_UNITED.COLOR,
  backgroundVideoPath: getBackgroundVideoPath({
    filename: BACKGROUND_VIDEOS.FREE_UNITED.FILENAME,
  }),
  challengeIntroSeen: false,
  hideScrollBar: false,
  seenFirstFeedItem: false,
};

const store = createContext(initialState);
const { Provider } = store;

const STORE_ACTIONS = {
  APPLICATION: {
    IS_LOADED: "APPLICATION:IS_LOADED",
    SHOW_NAVIGATION: "APPLICATION:SHOW_NAVIGATION",
    HIDE_NAVIGATION: "APPLICATION:HIDE_NAVIGATION",
    SHOW_PIP: "APPLICATION:SHOW_PIP",
    HIDE_PIP: "APPLICATION:HIDE_PIP",
  },
  UPDATE_VIDEO_BACKGROUND_INDEX: "UPDATE_VIDEO_BACKGROUND_INDEX",
  SET_VIDEO_BACKGROUND: "SET_VIDEO_BACKGROUND",
  SET_VIDEO_BACKGROUND_COLOR: "SET_VIDEO_BACKGROUND_COLOR",
  CHALLENGE_INTRO_SEEN: "CHALLENGE_INTRO_SEEN",
  HIDE_SCROLLBAR: "HIDE_SCROLLBAR",
  SHOW_SCROLLBAR: "SHOW_SCROLLBAR",
  SEEN_FIRST_FEED_ITEM: "SEEN_FIRST_FEED_ITEM",
};

// Video background constants

const backgroundVideosIndexed = Object.values(BACKGROUND_VIDEOS)
  .filter((video) => video.NAME !== BACKGROUND_VIDEOS.FREE_UNITED.NAME)
  .map((video) => ({ FILENAME: video.FILENAME, COLOR: video.COLOR }));

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    const index =
      state.videoIndex >= backgroundVideosIndexed.length - 1
        ? 0
        : state.videoIndex + 1;

    switch (action.type) {
      case STORE_ACTIONS.APPLICATION.IS_LOADED:
        return {
          ...state,
          application: {
            ...state.application,
            isLoaded: true,
          },
        };

      case STORE_ACTIONS.APPLICATION.SHOW_NAVIGATION:
        return {
          ...state,
          application: {
            ...state.application,
            isNavigationVisible: true,
          },
        };

      case STORE_ACTIONS.APPLICATION.SHOW_PIP:
        return {
          ...state,
          application: {
            ...state.application,
            isPipVideoVisible: true,
          },
        };

      case STORE_ACTIONS.APPLICATION.HIDE_PIP:
        return {
          ...state,
          application: {
            ...state.application,
            isPipVideoVisible: false,
          },
        };

      case STORE_ACTIONS.APPLICATION.HIDE_NAVIGATION:
        return {
          ...state,
          application: {
            ...state.application,
            isNavigationVisible: false,
          },
        };

      case STORE_ACTIONS.UPDATE_VIDEO_BACKGROUND_INDEX:
        return {
          ...state,
          videoIndex: index,
          backgroundVideoColor: backgroundVideosIndexed[index].COLOR,
          backgroundVideoPath: getBackgroundVideoPath({
            filename: backgroundVideosIndexed[index].FILENAME,
          }),
        };

      case STORE_ACTIONS.SET_VIDEO_BACKGROUND:
        return {
          ...state,
          backgroundVideoPath: getBackgroundVideoPath({
            filename: BACKGROUND_VIDEOS[action.name].FILENAME,
          }),
        };

      case STORE_ACTIONS.SET_VIDEO_BACKGROUND_COLOR:
        return {
          ...state,
          backgroundVideoColor: action.color,
        };

      case STORE_ACTIONS.CHALLENGE_INTRO_SEEN:
        return {
          ...state,
          challengeIntroSeen: true,
        };

      case STORE_ACTIONS.HIDE_SCROLLBAR:
        return {
          ...state,
          hideScrollbar: true,
        };
      case STORE_ACTIONS.SHOW_SCROLLBAR:
        return {
          ...state,
          hideScrollbar: false,
        };

      case STORE_ACTIONS.SEEN_FIRST_FEED_ITEM:
        return {
          ...state,
          seenFirstFeedItem: true,
        };

      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, STORE_ACTIONS, StateProvider };

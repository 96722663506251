
import React from "react";
import styled, { css } from "styled-components";

import { ASPECT_RATIOS } from "../../constants/aspect-ratios";

const FullscreenVideoBox = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;

  ${({ theme, fillBackground }) =>
    fillBackground &&
    css`
      background-color: ${theme.black};
    `}
`;

const FullscreenVideoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 0;
  padding-bottom: ${ASPECT_RATIOS.WIDESCREEN.PADDING};

  @media (${({ theme }) => theme.respondTo.widescreen}) {
    height: 100%;
    padding-bottom: 0;
  }

  // This breakpoint makes the video cover the container.
  @media (${({ theme }) => theme.respondTo.desktopL}) {
    width: calc(100vh * ${ASPECT_RATIOS.WIDESCREEN.RATIO});
    height: 100%;
    padding-bottom: 0;

    @media (${({ theme }) => theme.respondTo.widescreen}) {
      width: 100%;
      height: calc(100vh * ${ASPECT_RATIOS.WIDESCREEN.RATIO});
    }
  }
`;

const FullscreenVideo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const FullscreenVideoContainer = ({ children, fillBackground = true }) => {
  return (
    <FullscreenVideoBox fillBackground={fillBackground}>
      <FullscreenVideoWrapper>
        <FullscreenVideo>
          {children}
        </FullscreenVideo>
      </FullscreenVideoWrapper>
    </FullscreenVideoBox>
  );
};

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
import "dayjs/locale/nl";
dayjs.locale("nl");

const UTC_OFFSET_AMSTERDAM_HOURS = 2;

dayjs.extend(utc).extend(isBetween);

export const dateTimeService = {
  getNow() {
    return dayjs.utc().add(UTC_OFFSET_AMSTERDAM_HOURS, "hour");
  },

  getUtcDateTime({ dateTime }) {
    return dayjs(dateTime).utc().add(UTC_OFFSET_AMSTERDAM_HOURS, "hour");
  },

  getDateFromUnix({ unix }) {
    return dayjs(unix * 1000);
  },
};

import styled, { css } from "styled-components";

export const Title = styled.h1`
  font-size: ${({ small }) => (small ? "1.5rem" : "4.8rem")};
  @media (${({ theme }) => theme.respondTo.tablet}) {
    font-size: ${({ small }) => (small ? "1.6rem" : "6rem")};
  }
  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: ${({ small }) => (small ? "1.8rem" : "10rem")};
  }
  color: ${({ theme, color }) => (color ? theme[color] : theme.black)};
  display: block;
  ${({ center }) => center && `text-align: center;`}
`;

export const TitleShadow = styled.span`
  color: inherit;
  ${({ noShadow }) =>
    !noShadow &&
    css`
      box-shadow: 0 0 0 0.1em ${({ theme }) => theme.white};
      background-color: ${({ theme }) => theme.white};
      box-decoration-break: clone;
    `}

  display: inline;
`;

import React, { useCallback, useEffect, useState } from "react";
import Ticker from "react-ticker";
import styled, { css, withTheme } from "styled-components";
import {
  useMediaQuery,
  useThrottledFn,
  useWindowResize,
} from "beautiful-react-hooks";
import { motion } from "framer-motion";

import { ASPECT_RATIOS } from "../constants/aspect-ratios";
import { EASINGS } from "../constants/easings";
import { getViewportSizes } from "../utils/browser";
import { useStore } from "../hooks/store";
import { apiLowlands } from "../apis/api-lowlands";
import { getYoutubeVideoUrl } from "../utils/helpers";
import { CloseButton, CloseButtonTopLeft } from "./interface/CloseButton";
import { LiveStreamVideo } from "./LiveStreamVideo";
import { LiveStreamControls } from "./layout/LiveStreamControls";
import { ZoomControls } from "./layout/ZoomControls";
import televisionNoiseImage from "../static/images/television-noise.jpg";
import { ReactComponent as MutedSVG } from "../static/icons/audio-muted.svg";
import { ReactComponent as UnmutedSVG } from "../static/icons/audio-unmuted.svg";

const BOX_MOBILE_WIDTH_PX = 125;
const BOX_MOBILE_HEIGHT_PX =
  BOX_MOBILE_WIDTH_PX / ASPECT_RATIOS.WIDESCREEN.RATIO;
const BOX_MOBILE_INITIAL_OFFSET_X_PX = 10;
const BOX_MOBILE_INITIAL_OFFSET_Y_PX = 10;
const BOX_DESKTOP_WIDTH_PX = 270;
const BOX_DESKTOP_HEIGHT_PX =
  BOX_DESKTOP_WIDTH_PX / ASPECT_RATIOS.WIDESCREEN.RATIO;
const BOX_DESKTOP_INITIAL_OFFSET_X_PX = 30;
const BOX_DESKTOP_INITIAL_OFFSET_Y_PX = 30;
const TELEVISION_NOISE_DURATION_MS = 3500; // Set to 3500 to hide any initial Youtube branding and other visual clutter.
const TICKER_BANNER_MOBILE_HEIGHT_PX = 15;
const TICKER_BANNER_MOBILE_WIDTH_PX = 103; // Needs to be fixed otherwise the layout is calculated incorrectly.
const TICKER_BANNER_DESKTOP_HEIGHT_PX = 18;
const TICKER_BANNER_DESKTOP_WIDTH_PX = 110; // Needs to be fixed otherwise the layout is calculated incorrectly.

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  pointer-events: none;

  ${CloseButton} {
    z-index: 5;
  }
`;

const VideoBox = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: ${BOX_MOBILE_WIDTH_PX / 10}rem;
  height: ${BOX_MOBILE_HEIGHT_PX / 10}rem;
  pointer-events: none;
  background-color: ${({ theme }) => theme.black};
  overflow: hidden;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.12);

  ${({ isVisible }) =>
    isVisible &&
    css`
      pointer-events: auto;
    `}

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: ${BOX_DESKTOP_WIDTH_PX / 10}rem;
    height: ${BOX_DESKTOP_HEIGHT_PX / 10}rem;
    box-shadow: 0 0.6rem 0.9rem rgba(0, 0, 0, 0.12);
  }
`;

const TelevisionNoiseWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
`;

const TelevisionNoiseImage = styled(motion.img)`
  width: 100%;
  height: 110%;
  object-fit: cover;
`;

const TickerWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0.8rem;
  left: 0;
  height: ${TICKER_BANNER_MOBILE_HEIGHT_PX / 10}rem;
  background-color: ${({ theme }) => theme.red};
  z-index: 3;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    bottom: 1rem;
    height: ${TICKER_BANNER_DESKTOP_HEIGHT_PX / 10}rem;
  }
`;

const TickerBannerWrapper = styled.div`
  display: flex;
  height: 100%;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 1.1rem;
  line-height: ${TICKER_BANNER_MOBILE_HEIGHT_PX / 10}rem;
  width: ${TICKER_BANNER_MOBILE_WIDTH_PX / 10}rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 1.2rem;
    line-height: ${TICKER_BANNER_DESKTOP_HEIGHT_PX / 10}rem;
    width: ${TICKER_BANNER_DESKTOP_WIDTH_PX / 10}rem;
  }
`;

const TickerBannerRed = styled.span`
  background-color: ${({ theme }) => theme.red};
  color: ${({ theme }) => theme.white};
  padding: 0 0.5rem;
  white-space: nowrap;
`;

const TickerBannerWhite = styled.span`
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.red};
  padding: 0 0.5rem;
  white-space: nowrap;
`;

const MuteToggleButton = styled.div`
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 4rem;
`;

const FullscreenButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 4;
`;

export const PipVideoContainer = withTheme(({ theme }) => {
  const { state } = useStore();
  const isDesktopOrBigger = useMediaQuery(`(${theme.respondTo.desktop})`);
  const [isVisible, setIsVisible] = useState(false);
  const [showTelevisionNoise, setShowTelevisionNoise] = useState(true);
  const [canStartLiveStream, setCanStartLiveStream] = useState(false);
  const [isLiveStreamStarted, setIsLiveStreamStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showFullscreenInterface, setShowFullscreenInterface] = useState(false);
  const [liveStreamUrl, setLiveStreamUrl] = useState(null);
  const [isLiveStream, setIsLiveStream] = useState(null);
  const [zoomUrl, setZoomUrl] = useState(null);
  const [showZoomCta, setShowZoomCta] = useState(false);

  const boxWidth = isDesktopOrBigger
    ? BOX_DESKTOP_WIDTH_PX
    : BOX_MOBILE_WIDTH_PX;
  const boxHeight = isDesktopOrBigger
    ? BOX_DESKTOP_HEIGHT_PX
    : BOX_MOBILE_HEIGHT_PX;
  const boxOffsetX = isDesktopOrBigger
    ? BOX_DESKTOP_INITIAL_OFFSET_X_PX
    : BOX_MOBILE_INITIAL_OFFSET_X_PX;
  const boxOffsetY = isDesktopOrBigger
    ? BOX_DESKTOP_INITIAL_OFFSET_Y_PX
    : BOX_MOBILE_INITIAL_OFFSET_Y_PX;
  const getBoxRightX = () => getViewportSizes().width - boxWidth - boxOffsetX;
  const [boxRightX, setBoxRightX] = useState(getBoxRightX());
  const tickerHeight = isDesktopOrBigger
    ? TICKER_BANNER_DESKTOP_HEIGHT_PX
    : TICKER_BANNER_MOBILE_HEIGHT_PX;

  const makeFullscreen = () => {
    setIsFullscreen(true);
    setShowFullscreenInterface(false);
    isDesktopOrBigger && setIsMuted(false);
  };

  const minimize = () => {
    setIsFullscreen(false);
    setShowFullscreenInterface(false);
    setIsMuted(true);
  };

  const handlePlayerStart = () => {
    setIsVisible(true);
    setIsLiveStreamStarted(true);

    setTimeout(() => {
      setShowTelevisionNoise(false);
    }, TELEVISION_NOISE_DURATION_MS);
  };

  const handlePlayerPause = () => {
    setShowTelevisionNoise(true);
  };

  const handlePlayerResume = () => {
    setShowTelevisionNoise(false);
  };

  const handleBoxAnimationEnd = () => {
    if (isFullscreen) {
      setShowFullscreenInterface(true);
    }
  };

  /*
  To delay the loading of the live stream for performance / network reasons we wait for the
  first application state change to show the video. The initial loading of the video will
  trigger the visibility when it starts playing.
   */
  useEffect(() => {
    if (state.application.isPipVideoVisible && liveStreamUrl) {
      if (isLiveStreamStarted) {
        setIsVisible(true);
      } else {
        setCanStartLiveStream(true);
      }
    } else {
      setIsVisible(false);
    }
  }, [state.application.isPipVideoVisible, liveStreamUrl, isLiveStreamStarted]);

  const getStageData = useCallback(async () => {
    const {
      isLive,
      livestreamId,
      zoomUrl,
    } = await apiLowlands.getHighlightedStage();

    const hasLiveStream = !!livestreamId;
    const hasZoomUrl = !!zoomUrl;

    if (isLive === "live") {
      setLiveStreamUrl(getYoutubeVideoUrl({ videoId: livestreamId }));
    }

    if (hasLiveStream || hasZoomUrl) {
      if (hasLiveStream) setIsLiveStream(true);

      if (hasZoomUrl) setZoomUrl(zoomUrl);

      if (hasLiveStream && hasZoomUrl) setShowZoomCta(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getStageData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useWindowResize(
    useThrottledFn(() => {
      setBoxRightX(getBoxRightX());
    }, 500)
  );

  return (
    <Wrapper>
      <VideoBox
        initial={{
          x: boxRightX,
          y: boxOffsetY,
          opacity: 0,
        }}
        animate={{
          x: isFullscreen ? 0 : boxRightX,
          y: isFullscreen ? 0 : boxOffsetY,
          scale: isVisible ? 1 : 0.5,
          opacity: isVisible ? 1 : 0,
          width: isFullscreen ? "100%" : boxWidth,
          height: isFullscreen ? "100%" : boxHeight,
          transition: {
            duration: isLiveStreamStarted ? 0.5 : 0.3,
            ease: isLiveStreamStarted
              ? EASINGS.EASE_OUT_EXPO
              : EASINGS.EASE_OUT_BACK,
          },
        }}
        isVisible={isVisible}
        onAnimationComplete={handleBoxAnimationEnd}
      >
        <LiveStreamVideo
          canStartLiveStream={canStartLiveStream}
          liveStreamUrl={liveStreamUrl}
          isMuted={isMuted}
          showMobileControls={showFullscreenInterface}
          onPlayerStart={handlePlayerStart}
          onPlayerPause={handlePlayerPause}
          isOpen={isFullscreen}
          onPlayerResume={handlePlayerResume}
        />
        {showTelevisionNoise && (
          <TelevisionNoiseWrapper>
            <TelevisionNoiseImage
              src={televisionNoiseImage}
              alt={"television noise"}
              animate={{
                y: showTelevisionNoise ? "-6%" : 0,
                transition: {
                  duration: TELEVISION_NOISE_DURATION_MS / 1000,
                  ease: "linear",
                  yoyo: Infinity,
                },
              }}
            />
          </TelevisionNoiseWrapper>
        )}
        {isFullscreen ? (
          showFullscreenInterface && (
            <>
              <CloseButtonTopLeft onClick={minimize}>
                <CloseButton />
              </CloseButtonTopLeft>
              {isLiveStream && (
                <>
                  <ZoomControls
                    showZoomPanel={false}
                    showZoomCta={showZoomCta}
                    zoomUrl={zoomUrl}
                    isPlaying={true}
                  />
                  <LiveStreamControls isPlaying={true} />
                </>
              )}
            </>
          )
        ) : (
          <>
            <TickerWrapper>
              <Ticker speed={2} height={tickerHeight} move={isVisible}>
                {() => (
                  <TickerBannerWrapper>
                    <TickerBannerRed>Live</TickerBannerRed>
                    <TickerBannerWhite>FREE:UNITED</TickerBannerWhite>
                  </TickerBannerWrapper>
                )}
              </Ticker>
            </TickerWrapper>
            <FullscreenButton onClick={makeFullscreen} />
          </>
        )}
      </VideoBox>
    </Wrapper>
  );
});

import styled from "styled-components";
import { Button } from "./Button.jsx";

export const Form = styled.form`
  margin-top: 0;
  ${Button} {
    float: right;
  }
  &::after {
    content: "";
    clear: both;
    display: table;
  }
`;

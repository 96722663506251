import styled from "styled-components";

export const AspectRatioBox = styled.div`
  height: 0;
  width: 100%;
  /* Ratio allows for values like 16/9, and ratioHeight can be '56.25%' (which produce the same result). */
  padding-bottom: ${({ ratio, ratioHeight }) =>
    ratioHeight ? ratioHeight : `${(1 / ratio) * 100}%`};
  overflow: hidden;
  position: relative;
  background: ${({ theme }) => theme.black};
  background: ${({ theme, background }) => theme[background]};

  img,
  video,
  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

import React, { useState, useEffect, useCallback } from "react";
import styled, { withTheme } from "styled-components";
import { useMediaQuery } from "beautiful-react-hooks";
import { motion, useAnimation } from "framer-motion";
import { useLocation } from "react-router-dom";

import { EASINGS } from "../constants/easings";
import { useClickOutside } from "../hooks/click-outside";
import { useDimensions } from "../hooks/dimensions";
import { useStore } from "../hooks/store";
import { MainNavigation } from "./MainNavigation";
import { SubNavigation } from "./SubNavigation";
import { DragHandle } from "./interface/DragHandle";
import { HorizontalSimpleSlider } from "./layout/HorizontalSimpleSlider";
import { ReactComponent as LogoLowlands } from "../static/icons/lowlands-logo.svg";
import { ReactComponent as LogoNpo3fm } from "../static/icons/npo-3fm.svg";
import { ReactComponent as LogoNpo } from "../static/icons/npo.svg";
import { ReactComponent as Logo3voor12 } from "../static/icons/3voor12.svg";
import LogoNpo3fmPng from "../static/icons/npo-3fm.png";

const MOBILE_VISIBILITY_PX = 66;
const DESKTOP_VISIBILITY_PX = 70;
const MINIMIZE_PANEL_DURATION_S = 1;
const OPEN_PANEL_DURATION_S = 0.7;
const CLOSE_PANEL_DURATION_S = 0.5;
const CLOSE_PANEL_DELAY_MS = 1000;
const RESET_SCROLL_DELAY_MS = 800;

const MotionWrapper = styled(motion.div)`
  position: fixed;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 2;
`;

const Background = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: -5rem;
  border-radius: 1rem 1rem 0 0;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0rem -0.3rem 0.6rem rgba(0, 0, 0, 0.12);

  @media (${({ theme }) => theme.respondTo.desktop}) {
    top: 7.5rem;
    box-shadow: 0rem -0.6rem 0.9rem rgba(0, 0, 0, 0.12);
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  padding-bottom: 1.6rem;
  font-family: ${({ theme }) => theme.fontFamily};

  ${({ preventClicks }) =>
    preventClicks &&
    `
    pointer-events: none;
  `}
`;

const MotionDragHandleWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  padding-top: 1rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    display: none;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding-top: 3.5rem;
    padding-bottom: 3rem;
  }
`;

const SubNavWrapper = styled.div`
  order: 1;
  width: 100%;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    order: 2;
    margin: 0 auto;
    width: auto;
  }
`;

const Copyright = styled.div`
  order: 2;
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-left: 1.6rem;
  font-size: 1.1rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    order: 1;
    width: auto;
    margin-top: 0;
    font-size: 1.6rem;
    padding-left: 3rem;
    padding-right: 2rem;
  }
`;

const StyledLogoLowlands = styled(LogoLowlands)`
  margin-right: 0.6rem;
  width: 2.4rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin-right: 0.8rem;
    width: 3.5rem;
  }
`;

const PartnerLogos = styled.div`
  order: 3;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  padding-right: 1.6rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin-top: 0;
    width: auto;
    padding-left: 2rem;
    padding-right: 3rem;
  }
`;

const StyledLogoNpo = styled(LogoNpo)`
  width: 1.5rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 2.2rem;
  }
`;

const StyledLogoNpo3fm = styled.img`
  width: 3.6rem;
  margin-left: 1.6rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 5.2rem;
    margin-left: 2.4rem;
  }
`;

const StyledLogo3voor12 = styled(Logo3voor12)`
  width: 2.5rem;
  margin-left: 1.6rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 3.6rem;
    margin-left: 2.4rem;
  }
`;

export const BottomNavigationPanel = withTheme(({ theme }) => {
  const { state } = useStore();
  const isDesktopOrBigger = useMediaQuery(`(${theme.respondTo.desktop})`);
  const [wrapperRef, wrapperDimensions] = useDimensions();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [panelVisibilityPx, setPanelVisibilityPx] = useState(
    isDesktopOrBigger ? DESKTOP_VISIBILITY_PX : MOBILE_VISIBILITY_PX
  );
  const controls = useAnimation();
  const location = useLocation();
  let delayedCloseTimeout = null;

  const [clickOutsideRef] = useClickOutside(() => {
    if (isPanelOpen) {
      clearTimeout(delayedCloseTimeout);
      setIsPanelOpen(false);
    }
  });

  const handleWrapperClick = () => {
    if (isPanelOpen) return;

    setIsPanelOpen(!isPanelOpen);
  };

  const handleHoverStart = () => {
    clearTimeout(delayedCloseTimeout);
    setIsPanelOpen(true);
  };

  const handleHoverEnd = () => {
    delayedCloseTimeout = setTimeout(() => {
      setIsPanelOpen(false);
    }, CLOSE_PANEL_DELAY_MS);
  };

  const handleDragHandleClick = (event) => {
    event.stopPropagation();
    setIsPanelOpen(!isPanelOpen);
  };

  const minimizePanel = useCallback(() => {
    controls.start({
      y: 0 - panelVisibilityPx,
      transition: {
        duration: MINIMIZE_PANEL_DURATION_S,
        ease: EASINGS.EASE_OUT_EXPO,
      },
    });
  }, [controls, panelVisibilityPx]);

  const openPanel = useCallback(() => {
    controls.start({
      y: 0 - wrapperDimensions.height,
      transition: {
        duration: OPEN_PANEL_DURATION_S,
        ease: EASINGS.EASE_OUT_EXPO,
      },
    });
  }, [controls, wrapperDimensions.height]);

  const hidePanel = useCallback(() => {
    controls.start({
      y: 0,
      transition: {
        duration: CLOSE_PANEL_DURATION_S,
        ease: EASINGS.EASE_OUT_EXPO,
      },
    });
  }, [controls]);

  useEffect(() => {
    setPanelVisibilityPx(
      isDesktopOrBigger ? DESKTOP_VISIBILITY_PX : MOBILE_VISIBILITY_PX
    );
  }, [isDesktopOrBigger]);

  useEffect(() => {
    if (state.application.isNavigationVisible && !isPanelOpen) {
      minimizePanel();
    } else if (state.application.isNavigationVisible && isPanelOpen) {
      openPanel();
    } else {
      hidePanel();
    }
  }, [
    isPanelOpen,
    state.application.isNavigationVisible,
    minimizePanel,
    openPanel,
    hidePanel,
  ]);

  // Minimizes panel on route changes.
  useEffect(() => {
    if (!state.application.isNavigationVisible) return;
    setIsPanelOpen(false);
  }, [state.application.isNavigationVisible, location.pathname]);

  return (
    <MotionWrapper
      ref={wrapperRef}
      animate={controls}
      onClick={handleWrapperClick}
      onHoverStart={handleHoverStart}
      onHoverEnd={handleHoverEnd}
    >
      <div ref={clickOutsideRef}>
        <Background />

        <ContentWrapper preventClicks={!isPanelOpen}>
          <MotionDragHandleWrapper onClick={handleDragHandleClick}>
            <DragHandle />
          </MotionDragHandleWrapper>

          <HorizontalSimpleSlider
            resetScroll={!isPanelOpen}
            resetDelay={RESET_SCROLL_DELAY_MS}
          >
            <MainNavigation />
          </HorizontalSimpleSlider>

          <FooterWrapper>
            <SubNavWrapper>
              <HorizontalSimpleSlider
                resetScroll={!isPanelOpen}
                resetDelay={RESET_SCROLL_DELAY_MS}
              >
                <SubNavigation />
              </HorizontalSimpleSlider>
            </SubNavWrapper>

            <Copyright>
              <StyledLogoLowlands />
              <span>© Lowlands 2020</span>
            </Copyright>

            <PartnerLogos>
              <StyledLogoNpo />
              <StyledLogoNpo3fm src={LogoNpo3fmPng} />
              <StyledLogo3voor12 />
            </PartnerLogos>
          </FooterWrapper>
        </ContentWrapper>
      </div>
    </MotionWrapper>
  );
});

import React, { useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "beautiful-react-hooks";

import { useStore, STORE_ACTIONS } from "../hooks/store";
import { BACKGROUND_VIDEOS } from "../constants/media";
import { theme } from "../styling/theme";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 27rem 0 0;
  min-height: calc(100vh);

  @media (${({ theme }) => theme.respondTo.tablet}) {
    padding: 15vh 2.7rem 0;
  }
`;

const Challenge = ({ children, ...rest }) => {
  const { dispatch } = useStore();
  const isMobile = useMediaQuery(`(${theme.respondTo.mobile})`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isMobile]);

  // Set first background video
  useEffect(() => {
    dispatch({
      type: STORE_ACTIONS.SET_VIDEO_BACKGROUND,
      name: BACKGROUND_VIDEOS.FREE_UNITED.NAME,
    });
    dispatch({
      type: STORE_ACTIONS.SET_VIDEO_BACKGROUND_COLOR,
      color: BACKGROUND_VIDEOS.FREE_UNITED.COLOR,
    });
  }, [dispatch]);

  return <Wrapper {...rest}>{children}</Wrapper>;
};

export default Challenge;

import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const StyledSlideWrapper = styled.div`
  overflow-x: auto;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
  text-align: center;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    overflow: visible;
  }
`;

const StyledContentWrapper = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  text-align: left;
`;

export const HorizontalSimpleSlider = ({
  children,
  resetScroll,
  resetDelay = 0,
}) => {
  const ref = useRef(null);

  const resetScrollPosition = () => {
    setTimeout(() => {
      ref.current.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    }, resetDelay);
  };

  useEffect(() => {
    if (resetScroll) resetScrollPosition();
  }, [resetScroll]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledSlideWrapper ref={ref}>
      <StyledContentWrapper>{children}</StyledContentWrapper>
    </StyledSlideWrapper>
  );
};

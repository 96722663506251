import React, { createRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import { lowlandsService } from "../services/lowlands-service";
import { ROUTES } from "../routes";

import Layout from "../layouts/Challenge.jsx";

import { Title as TitleStyled } from "../components/interface/Title.jsx";
import { Textarea } from "../components/interface/Textarea.jsx";
import { Lead } from "../components/interface/Lead.jsx";
import { Button } from "../components/interface/Button.jsx";
import { Form } from "../components/interface/Form.jsx";
import { Input } from "../components/interface/Input.jsx";
import { Back } from "../components/interface/Back.jsx";
import { Panel } from "../components/interface/Panel.jsx";
import { ScrollToTop } from "../components/ScrollToTop.jsx";

const Title = styled(TitleStyled)`
  margin-bottom: 1.5rem;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    max-width: calc(100% - 20rem);
  }
  margin-left: auto;
  margin-right: auto;
`;

const Wrapper = styled.div`
  @media (${({ theme }) => theme.respondTo.tablet}) {
    /* 100vh - padding top of layout wrapper - height of the register panel */
    padding-bottom: calc((100vh - 26rem) - (30vh + 41.9rem));
  }
  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding-bottom: calc(85vh - (30vh + 30.1rem));
  }
`;

const FormMessage = styled.div`
  padding: 2rem 5rem;
  display: table;
  margin: 2rem auto 2rem;
  max-width: 50rem;
  &.success {
    background: ${({ theme }) => theme.green};
  }
  &.error {
    background: ${({ theme }) => theme.red};
  }
`;

const FormText = styled.h3`
  text-align: left;
  margin-bottom: 2.4rem;
  margin-top: 4rem;
  color: ${({ theme }) => theme.yellow};
  &:first-of-type {
    margin-top: 0;
  }
`;

const url = "https://onlineexperience.npo3fm.nl/api/register-ll2020-challenge";

const Register = () => {
  const form = createRef();
  const history = useHistory();
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState({});

  const handleInput = (name) => {
    if (!errors[name] && !errors["general"]) return;
    const temp = { ...errors };

    if (temp[name]) {
      delete temp[name];
    }
    if (temp["general"]) {
      delete temp["general"];
    }

    setErrors(temp);
  };

  const getFormData = () => {
    const formData = new FormData(form.current);
    const JSONFormData = {};
    formData.forEach((val, key) => (JSONFormData[key] = val));
    return JSON.stringify(JSONFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(errors).length > 0) return;

    const formData = getFormData();

    let res;
    setIsSending(true);
    try {
      res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: formData,
      });
    } catch (e) {
      setIsSending(false);
      setErrors({ general: "Er ging iets mis! Probeer het opnieuw." });
      return;
    }

    if (res.status !== 406 && res.status !== 200) {
      setIsSending(false);
      setErrors({ general: "Er ging iets mis! Probeer het opnieuw." });
      return;
    }
    const result = await res.json();

    if (res.status === 406) {
      setIsSending(false);
      return setErrors(result.errors);
    }

    setIsSending(false);
    history.push("/succes");
  };
  return (
    <Layout>
      <Helmet>
        <title>LLowparty aanmelden | Lowlands Free:United</title>
        <meta
          name="description"
          content="Ben jij van plan om thuis Lowlands te vieren én wil je kans maken op een onvergetelijk optreden bij jou in Achtertuinië of op de Costa Balkonia? Geef je dan nu op als Lowlands locatie!"
        />
      </Helmet>
      <ScrollToTop />
      <Wrapper>
        <Panel background="blue" small>
          <Back
            as={Link}
            to={
              lowlandsService.isPreState()
                ? ROUTES.HOME.URL
                : ROUTES.CHALLENGE.URL
            }
          >
            {"<"} Terug
          </Back>
          <Title color="yellow" noShadow center>
            LLowparty aanmelden
          </Title>
          <Lead>
            Ben jij van plan om thuis Lowlands te vieren én wil je kans maken op
            een onvergetelijk optreden bij jou in Achtertuinië of op de Costa
            Balkonia? Geef je dan nu op als Lowlands locatie! Wie weet staat 3FM
            in het weekend van 21, 22 en 23 augustus ineens voor je schutting
            met een uniek Lowlands-feestje, inclusief livemuziek.
          </Lead>

          <Form ref={form} onSubmit={handleSubmit}>
            <FormText>Je gegevens</FormText>
            <Input
              label="Naam"
              name="name"
              type="text"
              error={errors["name"]}
              onInput={handleInput}
              required
            />
            <Input
              label="Email"
              name="email"
              type="email"
              error={errors["email"]}
              onInput={handleInput}
              required
            />
            <Input
              label="Telefoonnummer"
              name="phone"
              type="text"
              error={errors["phone"]}
              onInput={handleInput}
              required
            />
            <FormText>Gegevens van je feest</FormText>
            <Input
              label="Naam feest"
              name="name_party"
              type="text"
              error={errors["name_party"]}
              onInput={handleInput}
              required
            />
            <Textarea
              label="Vertel over je feest"
              name="info_party"
              error={errors["info_party"]}
              onInput={handleInput}
            />
            <Input
              label="Adres"
              name="address_party"
              type="text"
              error={errors["address_party"]}
              onInput={handleInput}
              required
            />
            <Input
              label="Postcode"
              name="zipcode_party"
              type="text"
              error={errors["zipcode_party"]}
              onInput={handleInput}
              required
            />
            <Input
              label="Plaatsnaam"
              name="place_party"
              type="text"
              error={errors["place_party"]}
              onInput={handleInput}
              required
            />
            {errors["general"] && (
              <FormMessage className="error">{errors["general"]}</FormMessage>
            )}
            <p style={{ maxWidth: "44rem" }}>
              Wij hebben deze gegevens nodig om eventueel langs te kunnen komen.
              Je gegevens zullen niet met derden worden gedeeld.
            </p>
            <Button
              type="submit"
              background="green"
              color="white"
              disabled={isSending}
            >
              {isSending ? "Versturen..." : "Aanmelden"}
            </Button>
          </Form>
        </Panel>
      </Wrapper>
    </Layout>
  );
};

export default Register;

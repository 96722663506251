import React from "react";
import styled, { css } from "styled-components";
import { useMediaQuery } from "beautiful-react-hooks";

import { theme } from "../styling/theme";
import { dateTimeService } from "../services/date-time-service";
import { Title } from "./interface/Title";
import { AspectRatioBox } from "./interface/AspectRatioBox.jsx";
import { ReactComponent as CardBg } from "../static/images/card-bg.svg";
import { ReactComponent as CardBgDesktop } from "../static/images/card-bg-desktop.svg";

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.purple};
  padding: ${({ noPadding }) =>
    noPadding ? `3rem 2.4rem 0.75rem` : "3rem 2.4rem"};
  width: 100%;
  margin: 0 auto;
  text-align: center;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: 4rem 3rem;
  }

  ${({ big }) =>
    big &&
    css`
      @media (${({ theme }) => theme.respondTo.desktop}) {
        padding: 8rem;
      }
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}
`;

const Background = styled.div`
  position: absolute;
  transform: rotate(1deg);
  transform-origin: center;
  height: 100%;
  bottom: 3rem;
  right: 2rem;
  width: 70rem;

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    bottom: 0rem;
    right: 1rem;
    width: 80rem;
  }

  @media (${({ theme }) => theme.respondTo.desktopXXL}) {
    bottom: -10rem;
    width: 100rem;
  }

  > svg {
    width: 100%;
    transform: scale(1.25);
  }
`;

const StyledDesktopCardBg = styled(CardBg)`
  position: absolute;
  left: -100rem;
  bottom: -30rem;
  width: 100rem;
  height: auto;
  transform: rotate(-165deg);

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    bottom: -45rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  z-index: 1;
  position: relative;
  align-items: center;
`;

const DesktopStyledSubtitle = styled(Title)`
  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 5rem;
    margin-bottom: 3.4rem;
  }
`;

const DesktopStyledTitle = styled(Title)`
  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 12rem;
    max-width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const Content = styled.div`
  flex: 1;
  text-align: left;

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    text-align: center;
  }
`;

const ContentImageWrapper = styled.div`
  width: 35%;
`;

const Label = styled.span`
  background: ${({ theme }) => theme.red};
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontFamily};
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.4rem;
  padding: 0.1rem 0.5rem;
  @media (${({ theme }) => theme.respondTo.tablet}) {
    font-size: 1.6rem;
    padding: 0.1rem 0.7rem;
  }
`;

const AspectRatioBoxWrapper = styled.div`
  margin: 0 0 1.2rem;
  padding: ${({ noPadding }) => (noPadding ? `2rem 0.5rem` : "2rem 3rem")};
  position: relative;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    padding: ${({ noPadding }) => (noPadding ? `2rem 6rem` : "2rem 3rem")};
    margin: 0 0 2rem;
  }

  ${Title} {
    font-size: 2.2rem;
    @media (${({ theme }) => theme.respondTo.tablet}) {
      font-size: 2.8rem;
    }
    @media (${({ theme }) => theme.respondTo.desktop}) {
      font-size: 2.8rem;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  padding: 0 3rem;
  margin: 1.2rem 0 0;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    padding: 0;
    margin: 2rem 0 0;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: 0 1rem;
    margin: 2rem 0 0;
  }

  svg {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  img {
    margin: 0 auto;
  }
`;

const StyledTitle = styled(Title)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 2.2rem;
  line-height: 1.1;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    font-size: 2.8rem;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 2.8rem;
  }
`;

const StyledMobileTitle = styled(Title)`
  font-size: 1.75rem;
`;

const Card = ({ item, big, noPadding, withDate, ...rest }) => {
  const isDesktop = useMediaQuery(`(${theme.respondTo.desktop})`);
  const {
    title,
    placeholderImages,
    showTitle,
    startTime,
    endTime,
    isLive,
  } = item.stage;
  const startDate = dateTimeService.getDateFromUnix({ unix: startTime });
  const endDate = dateTimeService.getDateFromUnix({ unix: endTime });

  if (big && isDesktop) {
    return (
      <Wrapper big={big} {...rest}>
        <Background>
          <CardBgDesktop />
        </Background>
        <StyledDesktopCardBg />
        <ContentWrapper>
          <Content>
            <DesktopStyledSubtitle color="white" as="h3">
              {withDate && (
                <>
                  {startDate.format("dddd D MMMM")}
                  <br />
                </>
              )}
              {title} {startDate.format("HH:mm")} - {endDate.format("HH:mm")}
            </DesktopStyledSubtitle>

            <DesktopStyledTitle as="h2" color="white">
              {showTitle}
            </DesktopStyledTitle>
          </Content>
          <ContentImageWrapper>
            <AspectRatioBox
              style={{ background: "transparent" }}
              ratioHeight="125%"
            >
              <img src={placeholderImages.feedUpcoming} alt={title} />
            </AspectRatioBox>
          </ContentImageWrapper>
        </ContentWrapper>
      </Wrapper>
    );
  }

  if (isLive === "offline") {
    return (
      <Wrapper {...rest}>
        <Title small color="white" as="h2" center>
          {title}
        </Title>
        <ImageWrapper>
          <CardBg />
          <AspectRatioBoxWrapper noPadding>
            <AspectRatioBox
              ratioHeight="125%"
              style={{ background: "transparent" }}
            >
              <Title small color="purple" as="h3" center>
                Bedankt voor het kijken
              </Title>
            </AspectRatioBox>
          </AspectRatioBoxWrapper>
        </ImageWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper {...rest} noPadding={noPadding}>
      {isLive === "live" && <Label>Live</Label>}
      <StyledMobileTitle small color="white" as="h3" center>
        {withDate && (
          <>
            {startDate.format("dddd D MMMM")}
            <br />
          </>
        )}
        {title} {startDate.format("HH:mm")} - {endDate.format("HH:mm")}
      </StyledMobileTitle>
      <ImageWrapper>
        <CardBg />
        <AspectRatioBoxWrapper noPadding={noPadding}>
          <AspectRatioBox
            noPadding={noPadding}
            style={{ background: "transparent" }}
            ratioHeight="125%"
          >
            <img src={placeholderImages.feedUpcoming} alt={title} />
          </AspectRatioBox>
        </AspectRatioBoxWrapper>
      </ImageWrapper>
      <StyledTitle as="h2" color="white" center>
        {showTitle}
      </StyledTitle>
    </Wrapper>
  );
};

export default Card;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { useWindowResize } from "beautiful-react-hooks";
import { Link } from "react-router-dom";

import { ROUTES } from "../routes";
import { BACKGROUND_VIDEOS } from "../constants/media";
import { apiLowlands } from "../apis/api-lowlands";
import { theme } from "../styling/theme";

import Layout from "../layouts/Panel.jsx";

import { Title as TitleStyled } from "../components/interface/Title.jsx";
import { Slider, SliderStyled, Slide } from "../components/layout/Slider.jsx";
import Card from "../components/Card.jsx";

const SLIDE_SPACE_BETWEEN_PX = 12;
const SLIDE_SHOW_EXTRA = 0.5;
const SLIDE_AMOUNTS = {
  MOBILE: 1.5,
  TABLET: 2.5,
  DESKTOP: 4,
};

const PANEL_BREAKPOINT_PX = 1440;
const SLIDER_BREAKPOINTS = {
  MOBILE: `(${theme.respondTo.mobile})`,
  TABLET: `(${theme.respondTo.tablet}) and (max-width: ${PANEL_BREAKPOINT_PX}px)`,
  DESKTOP: `(min-width: ${PANEL_BREAKPOINT_PX}px)`,
};

const Title = styled(TitleStyled).attrs({
  center: true,
})`
  margin-bottom: 2.4rem;
  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin-bottom: 8rem;
  }
`;

const LinkStyled = styled(Link)`
  display: flex;
  width: 100%;
`;

const SliderWrapper = styled.div`
  width: 100vw;
  margin-left: -1.5rem;
  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin-left: -7.2rem;
  }
  @media ${SLIDER_BREAKPOINTS.DESKTOP} {
    margin-left: calc(
      -0.5 * (100vw - ${({ theme }) => theme.maxPanelWidth}) - 4.5rem
    );
  }

  ${SliderStyled} {
    padding-left: 1.5rem;
    padding-bottom: 2rem;
    @media (${({ theme }) => theme.respondTo.tablet}) {
      padding-left: 7.2rem;
    }
    @media (${({ theme }) => theme.respondTo.desktop}) {
      padding-bottom: 3rem;
    }
  }

  ${Slide} {
    display: flex;
    justify-content: center;
    align-items: stretch;
    box-sizing: border-box;
    box-shadow: 0rem 0.4rem 2rem rgba(0, 0, 0, 0.2);

    @media (${({ theme }) => theme.respondTo.desktop}) {
      box-shadow: 0rem 0.4rem 3rem rgba(0, 0, 0, 0.2);
    }
  }
`;

const BREAKPOINTS = {};
BREAKPOINTS[SLIDER_BREAKPOINTS.MOBILE] = {
  slidesPerView: SLIDE_AMOUNTS.MOBILE,
};
BREAKPOINTS[SLIDER_BREAKPOINTS.TABLET] = {
  slidesPerView: SLIDE_AMOUNTS.TABLET,
};
BREAKPOINTS[SLIDER_BREAKPOINTS.DESKTOP] = {
  slidesPerView: SLIDE_AMOUNTS.DESKTOP,
};

const NowLive = () => {
  const sliderRef = useRef();
  const [liveSets, setLiveSets] = useState([]);
  const [paddingLeft, setPaddingLeft] = useState(0);
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > PANEL_BREAKPOINT_PX
  );

  const getLiveSets = async () => {
    const data = await apiLowlands.getNowLive();
    if (data.status) return;
    setLiveSets(data);
  };

  useEffect(() => {
    getLiveSets();
  }, []);

  const setSliderPaddingLeft = () => {
    // take width of first slide within slider within sliderWrapper
    const slideWidth =
      sliderRef?.current?.childNodes[0].childNodes[0].getBoundingClientRect()
        .width + SLIDE_SPACE_BETWEEN_PX;
    // Show desktop amount of slides in the center + a little extra on the side
    setPaddingLeft(slideWidth * (1 - SLIDE_SHOW_EXTRA));
  };

  useEffect(() => {
    if (!isDesktop || !sliderRef?.current || liveSets.length === 0) return;

    setSliderPaddingLeft();
  }, [isDesktop, sliderRef, liveSets]); // eslint-disable-line react-hooks/exhaustive-deps

  useWindowResize(() => {
    if (window.innerWidth < PANEL_BREAKPOINT_PX) return setIsDesktop(false);
    setIsDesktop(true);
    setSliderPaddingLeft();
  });

  return (
    <Layout
      background="turquoise"
      color="purple"
      backgroundVideoName={BACKGROUND_VIDEOS.BLUE.NAME}
      paddingTop="8rem"
    >
      <Helmet>
        <title>LL2020 Live | Lowlands Free:United </title>
        <meta
          name="description"
          content="LL2020 overzichtspagina van alle live stages"
        />
      </Helmet>
      <Title color="purple">LL2020 Live</Title>
      <SliderWrapper ref={sliderRef}>
        {liveSets.length > 0 && (
          <Slider
            withNavigation
            breakpoints={BREAKPOINTS}
            spaceBetween={SLIDE_SPACE_BETWEEN_PX}
            style={isDesktop ? { paddingLeft, paddingRight: paddingLeft } : {}}
          >
            {liveSets.map((liveSet) => (
              <Slide key={liveSet.id}>
                {liveSet.stage.isLive === "live" ? (
                  <LinkStyled
                    to={{
                      pathname: `/stream/${liveSet.stage.slug}`,
                      state: { from: ROUTES.NOWLIVE.URL },
                    }}
                  >
                    <Card noPadding item={liveSet} />
                  </LinkStyled>
                ) : (
                  <Card item={liveSet} noPadding />
                )}
              </Slide>
            ))}
          </Slider>
        )}
      </SliderWrapper>
    </Layout>
  );
};

export default NowLive;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "./Button.jsx";

const SwitchStyled = styled.div`
  background: ${({ theme }) => theme.white};
  padding: 0.3rem;
  display: table;
  margin: 0 auto;
  box-sizing: content-box;
  position: relative;

  ${Button} {
    position: relative;
    z-index: 1;
    font-size: 1.4rem;
    padding: 0.3rem 0.8rem;
    @media (${({ theme }) => theme.respondTo.tablet}) {
      font-size: 1.8rem;
      padding: 0.5rem 1rem;
    }
    color: ${({ theme }) => theme.red};
    transition: all 0.4s ${({ theme }) => theme.transition};
    background: transparent;
    &.current {
      color: ${({ theme }) => theme.white};
    }
  }
`;

const SwitchBG = styled.div`
  position: absolute;
  width: 5rem;
  height: calc(100% - 0.6rem);
  top: 0.3rem;
  left: 0.3rem;
  background: ${({ theme }) => theme.red};
  content: "";
  width: ${({ width }) => width}px;
  transform: translateX(${({ x }) => x - 3}px);
  &.with-transition {
    transition: all 0.6s ${({ theme }) => theme.transition};
  }
`;

/**
 * @param {Array} routes - Array with objects containing a text and a to (link)
 * @param {Number} start - Start index
 * @param {Function} onSwitch - callback to call when switch occurs
 */

export const Switch = ({ routes, start = 0, onSwitch }) => {
  const [currentIndex, setCurrentIndex] = useState(start);
  const [bounds, setBounds] = useState({ width: 0, x: 0 });
  const [switchedOnce, setSwitchedOnce] = useState(false);

  const switchRef = useRef();

  const handleSwitch = (index) => {
    if (!switchedOnce) setSwitchedOnce(true);
    setCurrentIndex(index);
    onSwitch(index);
  };

  useEffect(() => {
    const child = switchRef?.current?.childNodes[currentIndex];
    if (!child) return;
    setTimeout(
      () =>
        setBounds({
          width: child.offsetWidth,
          x: child.offsetLeft,
        }),
      200
    );
  }, [setBounds, currentIndex, switchRef]);

  return (
    <SwitchStyled ref={switchRef}>
      {routes.map(({ text, to }, index) => {
        return (
          <Button
            key={text.split(" ").join("-")}
            as={to ? Link : "button"}
            to={to || null}
            onClick={to ? null : () => handleSwitch(index)}
            className={currentIndex === index ? "current" : null}
          >
            {text}
          </Button>
        );
      })}
      <SwitchBG {...bounds} className={switchedOnce && "with-transition"} />
    </SwitchStyled>
  );
};

import { IS_DEV } from "../constants/environment";
import { dateTimeService } from "./date-time-service";

const EVENT_BEFORE_DATE = "2020-08-20 17:00:00"; // Thursday
const EVENT_LIVE_DATE = "2020-08-21 17:00:00"; // Start first act
const EVENT_END_DATE = "2020-08-24 04:00:00"; // End last act

export const LOWLANDS_EVENT_STATES = {
  PRE: "PRE",
  BEFORE: "BEFORE",
  LIVE: "LIVE",
  AFTER: "AFTER",
};

export const SIMULATE_LIVE_STATE = IS_DEV && false; // Set to true to simulate live state for development purposes.

export const lowlandsService = {
  eventBeforeUtcDate: dateTimeService.getUtcDateTime({
    dateTime: EVENT_BEFORE_DATE,
  }),
  eventLiveUtcDate: dateTimeService.getUtcDateTime({
    dateTime: EVENT_LIVE_DATE,
  }),
  eventEndUtcDate: dateTimeService.getUtcDateTime({ dateTime: EVENT_END_DATE }),

  isPreState() {
    if (SIMULATE_LIVE_STATE) {
      return false;
    } else {
      return dateTimeService.getNow().isBefore(this.eventBeforeUtcDate);
    }
  },

  isBeforeState() {
    if (SIMULATE_LIVE_STATE) {
      return false;
    } else {
      return dateTimeService
        .getNow()
        .isBetween(this.eventBeforeUtcDate, this.eventLiveUtcDate);
    }
  },

  isLiveState() {
    if (SIMULATE_LIVE_STATE) {
      return true;
    } else {
      return dateTimeService
        .getNow()
        .isBetween(this.eventLiveUtcDate, this.eventEndUtcDate);
    }
  },

  isAfterState() {
    if (SIMULATE_LIVE_STATE) {
      return false;
    } else {
      return dateTimeService.getNow().isAfter(this.eventEndUtcDate);
    }
  },

  getCurrentEventState() {
    if (this.isPreState()) {
      return LOWLANDS_EVENT_STATES.PRE;
    } else if (this.isBeforeState()) {
      return LOWLANDS_EVENT_STATES.BEFORE;
    } else if (this.isLiveState()) {
      return LOWLANDS_EVENT_STATES.LIVE;
    } else if (this.isAfterState()) {
      return LOWLANDS_EVENT_STATES.AFTER;
    }
  },
};

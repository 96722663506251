import React from "react";
import styled from "styled-components";

const Label = styled.label`
  position: relative;
  font-size: 1.8rem;
  line-height: 120%;
  display: block;
  width: 100%;
  margin-bottom: 2.7rem;
`;

const LabelText = styled.span`
  letter-spacing: -0.005em;
  position: absolute;
  top: 0;
  left: 0;
  font-size: inherit;
  line-height: inherit;
  transform-origin: top left;
  transition: transform 0.4s ${({ theme }) => theme.transition};
  pointer-events: none;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 7.5rem;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-family: inherit;
  border-bottom: 1px solid ${({ theme }) => theme.white};
  &:focus,
  &:active,
  &:not(:placeholder-shown) {
    & + ${LabelText} {
      transform: translate(0, -1.5rem) scale(0.5);
    }
  }
  &:focus {
    outline: none;
  }
`;

const ErrorText = styled.span`
  padding: 1rem 0;
  display: block;
  text-align: left;
  color: ${({ theme }) => theme.black};
`;

export const Textarea = ({ label, type, name, required, error, onInput }) => {
  return (
    <Label>
      <StyledTextarea
        name={name}
        placeholder=" "
        onInput={() => onInput(name)}
        required={required}
      />
      <LabelText>{label}</LabelText>
      {error && <ErrorText>{error}</ErrorText>}
    </Label>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import { ROUTES } from "../routes";
import { apiLowlands } from "../apis/api-lowlands";
import {
  lowlandsService,
  LOWLANDS_EVENT_STATES,
} from "../services/lowlands-service";
import { getRandomItemFromArray } from "../utils/arrays";

import mainNavImageOntdek from "../static/images/main-nav-ontdek@2x.png";
import mainNavImageProgramma from "../static/images/main-nav-programma@2x.png";
import mainNavImageLive from "../static/images/main-nav-about@2x.png";

const MAIN_NAV_ITEMS = [
  {
    title: "Doe mee met LL2020",
    imageSrc: mainNavImageOntdek,
    to: lowlandsService.isPreState ? ROUTES.HOME.URL : ROUTES.CHALLENGE.URL,
    visibleInEventStates: [LOWLANDS_EVENT_STATES.PRE],
  },
  {
    title: "Ontdek LL2020",
    imageSrc: mainNavImageOntdek,
    to: ROUTES.HOME.URL,
    visibleInEventStates: [
      LOWLANDS_EVENT_STATES.BEFORE,
      LOWLANDS_EVENT_STATES.LIVE,
      LOWLANDS_EVENT_STATES.AFTER,
    ],
  },
  {
    title: "Nu Live",
    fallbackTitle: "Zometeen",
    imageSrc: mainNavImageLive, // TODO: Get final (fallback) image
    hasLiveImage: true,
    to: ROUTES.NOWLIVE.URL,
    visibleInEventStates: [LOWLANDS_EVENT_STATES.LIVE],
  },
  {
    title: "Programma",
    imageSrc: mainNavImageProgramma,
    to: ROUTES.PROGRAM.URL,
  },
  {
    title: "Over LL FREE:UNITED",
    imageSrc: mainNavImageLive,
    to: ROUTES.ABOUT.URL,
    visibleInEventStates: [
      LOWLANDS_EVENT_STATES.PRE,
      LOWLANDS_EVENT_STATES.BEFORE,
    ],
  },
];

const MainNav = styled.nav`
  padding: 1rem 1.2rem;
  display: flex;
  flex-wrap: nowrap;
  font-family: ${({ theme }) => theme.fontFamily};

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding-top: 0;
    padding-bottom: 1.2rem;
  }
`;

const MainNavItem = styled.div`
  width: 22rem; // Needs to be bigger than designed, otherwise the longer titles would not fit.
  height: 11.4rem;
  flex-shrink: 0;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  @media screen and (min-width: 400px) {
    width: 25rem;
    height: 15.8333rem;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    width: 30rem;
    height: 19rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
`;

const MotionMainNavLink = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.12);

  @media (${({ theme }) => theme.respondTo.desktop}) {
    box-shadow: 0 0.6rem 0.9rem rgba(0, 0, 0, 0.12);
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  display: block;
  height: 100%;
`;

const MainNavLinkImage = ({ className, src, alt }) => (
  <img className={className} src={src} alt={alt} />
);

const StyledMainNavLinkImage = styled(MainNavLinkImage)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
`;

const MainNavLinkTitle = ({ className, children }) => (
  <div className={className}>
    <p>{children}</p>
  </div>
);

const StyledMainNavLinkTitle = styled(MainNavLinkTitle)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.purple : theme.white};
    color: ${({ theme, isActive }) => (isActive ? theme.white : theme.black)};
    height: 3rem;
    line-height: 3rem;
    padding: 0 1rem;
    font-size: 1.8rem;
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    p {
      height: 3.5rem;
      line-height: 3.5rem;
      padding: 0 1.6rem;
      font-size: 2.2rem;
    }
  }
`;

export const MainNavigation = () => {
  const location = useLocation();
  const showLiveImage =
    lowlandsService.isBeforeState() || lowlandsService.isLiveState();
  const currentEventState = lowlandsService.getCurrentEventState();
  const [liveImageSrc, setLiveImageSrc] = useState(null);
  const [isInbetweenState, setIsInbetweenState] = useState(false);

  const getLiveStagesImages = useCallback(async () => {
    const feedData = await apiLowlands.getFeed();

    const stagesImages = feedData.reduce(
      (images, feedItem) => {
        if (
          feedItem.type === "nowLiveAtStage" &&
          (feedItem.stage.isLive === "live" ||
            feedItem.stage.isLive === "upcoming") &&
          feedItem.stage.placeholderImages.menu
        ) {
          images[feedItem.stage.isLive].push(
            feedItem.stage.placeholderImages.menu
          );
        }

        return images;
      },
      {
        live: [],
        upcoming: [],
      }
    );

    if (stagesImages.live.length) {
      setLiveImageSrc(getRandomItemFromArray({ array: stagesImages.live }));
    } else if (stagesImages.upcoming.length) {
      setLiveImageSrc(getRandomItemFromArray({ array: stagesImages.upcoming }));
    }

    const anyStageIsLive =
      feedData.findIndex(
        (feedItem) =>
          feedItem.type === "nowLiveAtStage" && feedItem.stage.isLive === "live"
      ) === -1;
    setIsInbetweenState(anyStageIsLive);
  }, []);

  useEffect(() => {
    if (showLiveImage) {
      getLiveStagesImages();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainNav>
      {MAIN_NAV_ITEMS.map(
        ({
          title,
          fallbackTitle,
          imageSrc,
          hasLiveImage,
          to,
          visibleInEventStates,
        }) => {
          const showLiveImage = hasLiveImage && liveImageSrc;
          title = hasLiveImage && isInbetweenState ? fallbackTitle : title;

          if (
            !visibleInEventStates ||
            visibleInEventStates.includes(currentEventState)
          ) {
            return (
              <MainNavItem key={title}>
                <MotionMainNavLink
                  whileHover={{ y: -7, transition: { ease: "easeInOut" } }}
                >
                  <StyledLink to={to}>
                    <StyledMainNavLinkImage
                      src={showLiveImage ? liveImageSrc : imageSrc}
                      hasLiveImage={showLiveImage}
                      alt={title}
                    />
                    <StyledMainNavLinkTitle isActive={to === location.pathname}>
                      {title}
                    </StyledMainNavLinkTitle>
                  </StyledLink>
                </MotionMainNavLink>
              </MainNavItem>
            );
          } else {
            return null;
          }
        }
      )}
    </MainNav>
  );
};

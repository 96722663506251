import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import { ROUTES } from "../routes";
import {
  lowlandsService,
  LOWLANDS_EVENT_STATES,
} from "../services/lowlands-service";

const SUB_NAV_ITEMS = [
  {
    title: "Doe Mee Met LL2020",
    to: ROUTES.CHALLENGE.URL,
    visibleInEventStates: [
      LOWLANDS_EVENT_STATES.BEFORE,
      LOWLANDS_EVENT_STATES.LIVE,
      LOWLANDS_EVENT_STATES.AFTER,
    ],
  },
  {
    title: "Coronaproof feesten",
    to: ROUTES.PARTY_SAFE.URL,
  },
  {
    title: "Over LL FREE:UNITED",
    to: ROUTES.ABOUT.URL,
    visibleInEventStates: [
      LOWLANDS_EVENT_STATES.LIVE,
      LOWLANDS_EVENT_STATES.AFTER,
    ],
  },
];

const SubNav = styled.nav`
  padding: 3rem 0.7rem;
  display: flex;
  flex-wrap: nowrap;
  font-family: ${({ theme }) => theme.fontFamily};

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  display: block;
  height: 100%;
`;

const SubNavLink = styled.div`
  position: relative;
  white-space: nowrap;
  padding: 0 0.9rem 0.4rem;
  font-size: 1.4rem;
  color: ${({ isActive, theme }) => (isActive ? theme.purple : theme.black)};
  ${StyledLink} {
    cursor: ${({ isActive }) => (isActive ? "default" : "pointer")};
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -0.1rem;
    left: 0.9rem;
    right: 0.9rem;
    height: 0.3rem;
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.purple : "transparent"};
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.1rem;
    background-color: ${({ theme }) => theme.gray};
    z-index: 0;
  }

  &:first-child {
    &::after {
      left: 0.9rem;
    }
  }

  &:last-child {
    &::after {
      right: 0.9rem;
    }
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 1.6rem;

    &::after {
      display: none;
    }

    &::before {
      height: 0.2rem;
    }

    &:hover {
      &::before {
        background-color: ${({ theme, isActive }) =>
          isActive ? theme.purple : theme.black};
      }
    }
  }

  @media (${({ theme }) => theme.respondTo.desktopXL}) {
    padding-bottom: 0;
  }
`;

export const SubNavigation = () => {
  const location = useLocation();
  const currentEventState = lowlandsService.getCurrentEventState();

  return (
    <SubNav>
      {SUB_NAV_ITEMS.map(({ title, to, visibleInEventStates }) => {
        if (
          !visibleInEventStates ||
          visibleInEventStates.includes(currentEventState)
        ) {
          return (
            <SubNavLink key={title} isActive={to === location.pathname}>
              <StyledLink to={to}>{title}</StyledLink>
            </SubNavLink>
          );
        } else {
          return null;
        }
      })}
    </SubNav>
  );
};

import React from "react";
import { Heading as HeadingStyled } from "./interface/Heading";
import styled from "styled-components";

const Heading = styled(HeadingStyled)`
  margin-bottom: 1.2rem;
`;

const Text = styled.div`
  p {
    line-height: 150%;
    font-size: 1.6rem;
    @media (${({ theme }) => theme.respondTo.tablet}) {
      font-size: 1.8rem;
    }
  }
`;

const TitleAndText = ({ title, text }) => {
  return (
    <div>
      <Heading color="yellow">{title}</Heading>
      <Text dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default TitleAndText;

const BASE_URL = '/api/';

const api = {
  get: async ({ url }) => {
    try {
      const res = await fetch(`${BASE_URL}${url}`);
      return res.json();
    } catch (error) {
      return { error };
    }
  },
};

export const apiLowlands = {
  getFeed() {
    return api.get({ url: "explore-feed.json" });
  },

  getTimeTable() {
    return api.get({ url: "timetable.json" });
  },

  getStage({ slug }) {
    return api.get({ url: `stage/${slug}.json` });
  },

  getHighlightedStage() {
    return api.get({ url: "highlighted-stage" });
  },

  getArtist({ slug }) {
    return api.get({ url: `artist/${slug}.json` });
  },

  getInfoPage({ slug }) {
    return api.get({ url: `page/${slug}.json` });
  },

  getNowLive() {
    return api.get({ url: "now-live.json" });
  },
};

import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "beautiful-react-hooks";
import { Helmet } from "react-helmet-async";

import { theme } from "../styling/theme";
import { ROUTES } from "../routes";
import { lowlandsService } from "../services/lowlands-service";

import Layout from "../layouts/Challenge.jsx";

import RegisterPanel from "../components/RegisterPanel.jsx";

import { Title as TitleStyled } from "../components/interface/Title.jsx";
import { Heading } from "../components/interface/Heading.jsx";
import { Back } from "../components/interface/Back.jsx";
import { Button } from "../components/interface/Button.jsx";
import { Panel } from "../components/interface/Panel.jsx";
import { ScrollToTop } from "../components/ScrollToTop.jsx";

const Wrapper = styled.div`
  @media (${({ theme }) => theme.respondTo.tablet}) {
    /* 100vh - padding top of layout wrapper - height of the register panel */
    padding-bottom: calc((100vh - 26rem) - (30vh + 41.9rem));
  }
  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding-bottom: calc(85vh - (30vh + 30.1rem));
  }
`;

const Title = styled(TitleStyled)`
  margin-bottom: 3rem;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    max-width: calc(100% - 20rem);
  }
  margin-left: auto;
  margin-right: auto;
`;

const Paragraph = styled.div`
  margin-bottom: 6rem;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const ButtonHolder = styled.div`
  ${Button} {
    margin-top: 1.6rem;
    display: table;
  }
`;

const ToolkitUrls = [
  {
    text: "Lowlands logo's (~3.5mb)",
    url:
      "https://content.omroep.nl/3fm/lowlands/2020/toolkit/Lowlands%20logo.zip",
  },
  {
    text: "Foto's van de podia (~27.5mb)",
    url:
      "https://content.omroep.nl/3fm/lowlands/2020/toolkit/Fotos%20Podia.zip",
  },
  {
    text: "Lowlands vlaggen (~36mb)",
    url: "https://content.omroep.nl/3fm/lowlands/2020/toolkit/Vlaggen.zip",
  },
  {
    text: "Bewegwijzering (~9mb)",
    url:
      "https://content.omroep.nl/3fm/lowlands/2020/toolkit/Bewegwijzering.zip",
  },
  {
    text: "Munten en overige (~4mb)",
    url: "https://content.omroep.nl/3fm/lowlands/2020/toolkit/Overig.zip",
  },
  {
    text: "Instagram frames (~0.6mb)",
    url:
      "https://content.omroep.nl/3fm/lowlands/2020/toolkit/Instagram%20frames.zip",
  },
  {
    text: "A4 frames (~0.9mb)",
    url: "https://content.omroep.nl/3fm/lowlands/2020/toolkit/A4%20frames.zip",
  },
];

const Toolkit = () => {
  const isMobile = useMediaQuery(`(${theme.respondTo.mobile})`);

  return (
    <Layout>
      <Helmet>
        <title>Download de LL Toolkit | Lowlands Free:United</title>
        <meta
          name="description"
          content="Download de LL Toolkit van Lowlands Free:United op 21 + 22 + 23 augustus en bouw je eigen stage!"
        />
      </Helmet>
      <ScrollToTop />
      <Wrapper>
        <Panel background="blue" small>
          <Back
            to={
              lowlandsService.isPreState()
                ? ROUTES.HOME.URL
                : ROUTES.CHALLENGE.URL
            }
          >
            {"<"} Terug
          </Back>
          <Title color="yellow" noShadow center>
            Download de LL Toolkit
          </Title>
          <Paragraph>
            <Heading color="yellow">Volledige toolkit</Heading>

            <ButtonHolder>
              <Button
                as="a"
                href="https://content.omroep.nl/3fm/lowlands/2020/toolkit/Toolkit%20Lowlands%20free%20united.zip"
                background="purple"
                color="white"
              >
                Volledige toolkit (~95mb)
              </Button>
            </ButtonHolder>
          </Paragraph>
          <Paragraph>
            <Heading color="yellow">Per item</Heading>
            <ButtonHolder>
              {ToolkitUrls.map(({ url, text }) => (
                <Button
                  key={url}
                  as="a"
                  href={url}
                  background="purple"
                  color="white"
                >
                  {text}
                </Button>
              ))}
            </ButtonHolder>
          </Paragraph>
        </Panel>
      </Wrapper>
      {!isMobile && <RegisterPanel />}
    </Layout>
  );
};

export default Toolkit;


import { useRef, useState, useLayoutEffect } from "react";

export const useDimensions = () => {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({})

  const measure = () => {
    window.requestAnimationFrame(() => {
      if (ref.current) {
        setDimensions({
          ...ref.current.getBoundingClientRect().toJSON(),
          scrollWidth: ref.current.scrollWidth,
          scrollHeight: ref.current.scrollHeight
        });
      } else {
        measure();
      }
    });
  };

  useLayoutEffect(() => {
    measure();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [ref, dimensions]
};
